export default {
  easeInCubic: 'cubic-bezier(.55, .055, .675, .19)',
  easeOutCubic: 'cubic-bezier(.215, .61, .355, 1)',
  easeInOutCubic: 'cubic-bezier(.645, .045, .355, 1)',
  easeInQuart: 'cubic-bezier(.895, .03, .685, .22)',
  easeOutQuart: 'cubic-bezier(.165, .84, .44, 1)',
  easeInOutQuart: 'cubic-bezier(.77, 0, .175, 1)',
  easeInQuint: 'cubic-bezier(.755, .05, .855, .06)',
  easeOutQuint: 'cubic-bezier(.23, 1, .32, 1)',
  easeInOutQuint: 'cubic-bezier(.86, 0, .07, 1)',
}
