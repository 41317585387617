import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import Link from 'components/Link'
import MarkdownRenderer from 'components/MarkdownRenderer'
import theme from 'styles/theme'
import trackGTM from 'utils/trackGTM'
import GA4 from 'utils/GA4'

import { PopupContext } from './context'
import {
  Actions,
  Body,
  Closer,
  Dismiss,
  Headline,
  Icon,
  BackgroundImage,
  PositionWrapper,
  Inner,
  Wrapper,
  Spacing,
  HeadlineMembership,
} from './Popup.style'

const Popup = ({ villageName }) => {
  const {
    body,
    close,
    cta,
    dismiss,
    headline,
    icon,
    showing,
    backgroundImage = {},
    condensedVersion,
    hexCode,
    legalCopy,
    type,
    secondaryCopy,
  } = useContext(PopupContext)

  const bgStyle = {
    backgroundColor: backgroundImage?.portrait?.gatsbyImageData
      ? 'transparent'
      : theme.colors.white,
  }

  const color =
    hexCode &&
    /#fff|#ffffff|white|rgb(255, 255, 255)/.test(hexCode.toLocaleLowerCase())
      ? 'black'
      : 'white'

  const ctaStyle =
    (type === 'membershipPopUp' && {
      backgroundColor: hexCode || '#000',
      borderColor: hexCode || '#000',
      color,
      maxWidth: 220,
      margin: '0 auto',
    }) ||
    {}

  useEffect(() => {
    if (!!showing && type === 'membershipPopUp') {
      GA4('membership_popup_visible', {
        village_name: villageName,
      })
    }
  }, [showing])

  return (
    [body, cta, dismiss, headline].some((s) => !!s) &&
    showing && (
      <Wrapper condensedVersion={condensedVersion} showing={showing}>
        <PositionWrapper>
          {backgroundImage ? (
            <BackgroundImage
              image={backgroundImage?.portrait?.gatsbyImageData}
              altText={backgroundImage?.altText}
            />
          ) : null}
          <Inner
            type={type}
            condensedVersion={condensedVersion}
            style={bgStyle}>
            {type === 'membershipPopUp' ? (
              <Closer
                type={type}
                hexcode={hexCode || '#000'}
                color={color}
                onClick={() => {
                  close()
                  trackGTM(
                    'Registration Overlay',
                    'Registration Overlay',
                    'Overlay Closed',
                    'Close'
                  )
                  GA4('membership_popup_close', {
                    village_name: villageName,
                  })
                }}
              />
            ) : (
              <Closer onClick={close} />
            )}
            {!!icon && <Icon>{icon}</Icon>}
            {type === 'membershipPopUp' && (
              <Spacing condensedVersion={condensedVersion} />
            )}
            {type === 'membershipPopUp' && !!headline ? (
              <HeadlineMembership
                condensedVersion={condensedVersion}
                hexCode={hexCode || '#000'}
                type={type}>
                {headline}
              </HeadlineMembership>
            ) : (
              !!headline && <Headline>{headline}</Headline>
            )}
            {type === 'membershipPopUp' && !!body
              ? !condensedVersion && (
                  <Body
                    hexCode={hexCode || '#000'}
                    type={type}
                    as={MarkdownRenderer}
                    html={body}
                  />
                )
              : !!body && <Body as={MarkdownRenderer} html={body} />}
            <Actions type={type}>
              {type === 'membershipPopUp' ? (
                <Button
                  onClick={() => {
                    trackGTM(
                      'Registration Overlay',
                      'Registration Overlay',
                      'Register CTA',
                      cta.label
                    )
                    GA4('register_popup_cta', { village_name: villageName })
                    GA4('membership_popup_registration', {
                      village_name: villageName,
                    })
                    close()
                  }}
                  className="popup-cta"
                  ctaStyle={ctaStyle}
                  as={Link}
                  level="primary"
                  to={cta.url}
                  external={cta && cta.url && cta.url.indexOf('http') === 0}
                  fixedSize="true">
                  {cta.label}
                </Button>
              ) : (
                !!cta &&
                !!cta.label &&
                !!cta.url && (
                  <Button
                    onClick={close}
                    className="popup-cta"
                    as={Link}
                    level="secondary"
                    to={cta.url}
                    external={cta.url.indexOf('http') === 0}
                    fixedSize="true">
                    {cta.label}
                  </Button>
                )
              )}
              {!!dismiss && (
                <Dismiss level="quaternary" fixedSize="true" onClick={close}>
                  {dismiss}
                </Dismiss>
              )}
            </Actions>
            {type === 'membershipPopUp' && !!secondaryCopy
              ? !condensedVersion && (
                  <Body
                    onClick={(e) => {
                      if (e.target.tagName === 'A') {
                        trackGTM(
                          'Registration Overlay',
                          'Registration Overlay',
                          'Sign In CTA',
                          e.target.text || 'SEE PERKS'
                        )
                        GA4('membership_popup_login', {
                          village_name: villageName,
                        })
                      }
                    }}
                    top={Number(1.875)}
                    secondaryCopy
                    hexCode={hexCode || '#000'}
                    type={type}
                    as={MarkdownRenderer}
                    html={secondaryCopy}
                  />
                )
              : !!secondaryCopy && (
                  <Body as={MarkdownRenderer} html={secondaryCopy} />
                )}

            {!!legalCopy && (
              <Body
                condensedVersion={condensedVersion}
                size={Number(0.75)}
                legalCopy={legalCopy}
                top={Number(4.6875)}
                hexCode={hexCode || '#000'}
                type={type}
                as={MarkdownRenderer}
                html={legalCopy}
              />
            )}
          </Inner>
        </PositionWrapper>
      </Wrapper>
    )
  )
}

Popup.propTypes = {
  villageName: PropTypes.string,
}

Popup.defaultProps = {
  villageName: '',
}

export default Popup
