import React from 'react'
import Link from 'components/Link'
import PropTypes from 'prop-types'
import * as icons from 'components/Icons'

import { Flex as SocialList } from 'rebass'
import { Container } from 'styles/sharedStyle'
import theme from 'styles/theme'
import trackGTM from 'utils/trackGTM'
import { SocialListItem, SocialListItemLabel } from './FooterSocial.style'

const FooterSocial = ({ socialItems, iconFillColor }) => {
  const iconName = (str) =>
    `${str.substr(0, 1).toUpperCase()}${str.substr(1).toLowerCase()}Icon`

  if (!socialItems) {
    return null
  }

  const socialItemsFilter = Object.keys(socialItems).filter(
    (key) => socialItems[key] !== null
  )

  if (socialItemsFilter.length < 1) {
    return null
  }

  return (
    <Container
      py={7}
      px={['20px', 0]}
      width="100%"
      maxWidth={['auto', '400px']}
      m={['auto', null, '0 0 0 20px']}>
      {socialItems && (
        <SocialList
          as="ul"
          mt="3px"
          mb={0}
          p={0}
          justifyContent={['center', null, 'flex-end']}
          flexWrap={['wrap', 'no-wrap']}
          style={{ listStyle: 'none' }}>
          {Object.keys(socialItems).map((key, index) => {
            const SocialIcon = icons[iconName(key)]

            if (!socialItems[key]) return null

            return (
              <SocialListItem as="li" key={`${key}-${index.toString()}`}>
                <Link
                  onClick={() => {
                    trackGTM(
                      'outbound link',
                      'navigation',
                      'social link',
                      socialItems[key]
                    )
                  }}
                  external
                  to={socialItems[key]}>
                  <SocialListItemLabel>{key}</SocialListItemLabel>
                  <SocialIcon
                    color={iconFillColor}
                    display="block"
                    height="32px"
                    width="32px"
                  />
                </Link>
              </SocialListItem>
            )
          })}
        </SocialList>
      )}
    </Container>
  )
}

FooterSocial.propTypes = {
  iconFillColor: PropTypes.string,
  socialItems: PropTypes.objectOf(PropTypes.string),
}

FooterSocial.defaultProps = {
  iconFillColor: theme.colors.sagemedium,
  socialItems: null,
}

export default FooterSocial
