import React, { useState } from 'react'
import propTypes from 'prop-types'
import ModalContext from './Modal.context'

const { arrayOf, node, oneOfType } = propTypes

const ModalPeovider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <ModalContext.Provider value={{ isModalOpen, setIsModalOpen }}>
      {children}
    </ModalContext.Provider>
  )
}

ModalPeovider.propTypes = {
  children: oneOfType([arrayOf(node), node]),
}

ModalPeovider.defaultProps = {
  children: null,
}

export default ModalPeovider
