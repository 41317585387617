import { rem } from 'polished'
import styled, { keyframes } from 'styled-components'
import theme from 'styles/theme'
import Button from 'components/Button'
import { Body16, Title60 } from 'components/Typography'

export const Text = styled.div`
  margin: 0 auto;
  max-width: 566px;
`

export const TextInner = styled.div`
  color: ${theme.colors.white};
  padding: 36px 40px;
  text-align: center;

  ${theme.mediaQueries.medium} {
    padding: 36px 20px;
  }

  ${({ direction }) =>
    direction > -1 &&
    `
    > * {
      .step-appear &,
      .step-enter & {
        opacity: 0;
        transform: translateY(150px);
      }

      .step-appear-active &,
      .step-appear-done &,
      .step-enter-active &,
      .step-enter-done & {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 1.4s ease, transform 1.4s cubic-bezier(.19, 1, .22, 1);
      }
    }

    ${Array.from(
      { length: 4 },
      (v, i) => `
      .step-appear-active & > :nth-child(${i + 1}),
      .step-appear-done & > :nth-child(${i + 1}) {
        transition-delay: ${0.3 + 0.1 * i}s;
      }

      .step-enter-active & > :nth-child(${i + 1}),
      .step-enter-done & > :nth-child(${i + 1}) {
        transition-delay: ${0.5 + 0.1 * i}s;
      }
    `
    ).join('')}
  `}
`

export const TextHeadline = styled(Title60)`
  font-size: ${rem(42)};

  ${theme.mediaQueries.medium} {
    font-size: ${rem(60)};
  }
`

export const TextButton = styled(Button)`
  margin-top: 40px;
  min-width: 204px;
  padding: 20px;

  span {
    display: inline;
  }
`

export const TextBody = styled(Body16)`
  font-size: ${rem(16)};
  margin-top: ${rem(20)};

  ${theme.mediaQueries.medium} {
    padding-left: 20px;
    padding-right: 20px;
  }

  + ${TextButton} {
    margin-top: 10px;
  }
`

const Roll = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

export const Ball = styled.svg.attrs(() => ({
  xmlns: 'http://www.w3.org/2000/svg',
  viewBox: '0 0 93 97',
  width: 93,
  height: 97,
}))`
  animation: ${Roll} 2s linear infinite;
  display: block;
  fill: #f4be37;
  height: auto;
  margin: 0 auto;
  position: relative;
  width: 50px;

  ${theme.mediaQueries.medium} {
    width: 93px;
  }
`
