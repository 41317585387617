import React from 'react'
import PropTypes from 'prop-types'
import theme from 'styles/theme'
import IconWrapper from './IconWrapper'

const FacebookIcon = ({ color, width, height, display }) => (
  <IconWrapper width={width} height={height} display={display}>
    <svg width={width} height={height} viewBox="0 0 40 40">
      <g transform="translate(2 2)" fill="none" fillRule="evenodd">
        <circle
          stroke={theme.colors.sagelight}
          strokeLinecap="square"
          cx="18"
          cy="18"
          r="18"
        />
        <path
          d="M22 12.656l-1.45.001c-1.137 0-1.358.561-1.358 1.384v1.816h2.713l-.354 2.845h-2.359V26h-2.827v-7.298H14v-2.845h2.365V13.76c0-2.435 1.431-3.76 3.522-3.76 1.001 0 1.862.077 2.113.112v2.544z"
          fill={color}
        />
      </g>
    </svg>
  </IconWrapper>
)

FacebookIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  display: PropTypes.string,
}

FacebookIcon.defaultProps = {
  color: theme.colors.coaldark,
  width: '40px',
  height: '40px',
  display: 'inline-block',
}

export default FacebookIcon
