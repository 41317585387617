import React from 'react'
import { keyframes } from 'styled-components'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'

export const appearingAnimation = (y = '0', x = '0') => keyframes`
  from {
    opacity: 0;
    transform: translate3d(${x}, ${y}, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`

export const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const animConfig = {
  whileInView: { y: 0, opacity: 1 },
  initial: { y: 80, opacity: 0 },
  transition: { duration: 0.2 },
  viewport: { once: true },
}

export const FadeInUpAnimationWrapper = (props) => {
  const { children, enabled } = props

  if (!enabled) {
    return <>{children}</>
  }

  /* eslint-disable no-unreachable */
  return <motion.div {...animConfig}>{children}</motion.div>
  /* eslint-enable no-unreachable */
}

FadeInUpAnimationWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  enabled: PropTypes.bool.isRequired,
}
