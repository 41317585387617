import React, { createRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import Dragger from 'utils/dragger'
import FlexCenter from './FlexCenter'
import OnboardingQOverview from './OnboardingQOverview'
import {
  AnswerSlide,
  AnswerSlider,
  AnswerSliderWrap,
  Arrow,
  BgSlide,
  BgSlider,
  Blob,
  Eyebrow,
  Heading,
  Inner,
  Item,
  ItemText,
  Question,
  SlickArrow,
  Wrapper,
} from './OnboardingQCarousel.style'

const OnboardingQCarousel = ({
  answers,
  eyebrow,
  headline,
  locale,
  overviewEyebrow,
  overviewHeadline,
  pager,
  setAnswers,
  step,
}) => {
  const rtl = ['ar', 'az', 'he', 'fa', 'ur'].indexOf(locale) > -1
  const dir = rtl ? 'rtl' : 'ltr'
  const [current, setCurrent] = useState(rtl ? answers.length : 0)
  const slider1 = createRef()
  const slider2 = createRef()
  const body = createRef()
  const [dragging, setDragging] = useState(false)
  const [dragger, setDragger] = useState(null)
  const [sliding, setSliding] = useState(false)
  const hasOverview = !!(overviewEyebrow || overviewHeadline)
  const onOverview =
    hasOverview && (rtl ? current : answers.length - current) <= 0
  const heading = {
    default: { eyebrow, headline },
    overview: {
      eyebrow: overviewEyebrow || eyebrow,
      headline: overviewHeadline || headline,
    },
  }[onOverview ? 'overview' : 'default']
  const blobs = [
    <path d="m21.3239 213.7399c24.0914 39.6643 64.6059 69.0084 109.8372 79.5541 18.329 4.2741 37.7968 5.5215 55.8329.1178 21.4977-6.44 39.1564-21.669 55.1119-37.4451 20.73-20.4962 40.0943-43.028 52.2499-69.5122 12.1536-26.4836 16.5264-57.4862 6.9059-84.9717-6.851-19.5752-20.1985-36.1337-33.9719-51.6431-17.8558-20.1049-37.1019-39.387-60.0283-53.4514-22.9264-14.0655-49.9815-22.6582-76.7519-19.8619-30.4284 3.1779-57.4782 20.4485-81.6371 39.2209-22.5024 17.4851-45.0427 39.1973-48.8438 67.4157-11.5049 44.9801-2.7968 90.9131 21.2952 130.5769z" />,
    <path d="m152.8427 285.9531c46.4257-1.0132 92.1176-21.3893 123.874-55.2407 12.8692-13.7171 23.6843-29.9319 28.0185-48.2328 5.1669-21.8128.7961-44.6979-4.9005-66.3833-7.4006-28.1741-17.2484-56.1843-34.1264-79.935-16.8778-23.7498-41.5643-43.026-70.1983-48.442-20.3928-3.8581-41.419-.5894-61.7489 3.5721-26.3541 5.3956-52.6905 12.405-76.3443 25.2055-23.6548 12.7998-44.6303 31.9061-55.5917 56.4583-12.4597 27.9069-11.0149 59.9351-6.8229 90.2134 3.9044 28.2021 11.4539 58.5497 34.0122 75.941 33.2352 32.4321 77.4027 47.8575 123.8283 46.8435z" />,
    <path d="m293.4059 101.4336c-15.3111-43.7323-48.834-80.7921-90.8825-100.4719-17.0391-7.9756-35.8218-13.2314-54.5883-11.6948-22.368 1.8306-42.8099 13.0406-61.6996 25.1382-24.5421 15.7169-48.1719 33.705-65.573 57.0481-17.4004 23.3426-28.1276 52.7128-24.4367 81.55 2.6278 20.5378 12.238 39.4769 22.483 57.4798 13.2819 23.3375 28.095 46.1599 47.5937 64.6499 19.4985 18.4911 44.1743 32.494 70.9416 35.3153 30.4248 3.2074 60.4774-8.0487 88.0147-21.3711 25.6491-12.4087 54.447-33.2518 64.0371-60.0211 20.6134-41.5409 19.4218-83.8905 4.11-127.6224z" />,
    <path d="m272.9107 249.1788c-33.2352 32.4328-79.7923 50.7953-126.2178 49.7813-46.4257-1.0132-92.1176-21.3898-123.874-55.242-12.8692-13.7175-23.6843-29.9327-28.0185-48.234-5.1669-21.8134-.7961-44.6989 4.9005-66.3848 7.4006-28.1747 17.2484-56.1856 34.1264-79.9368 16.8777-23.7505 41.5642-43.027 70.1982-48.4432 20.3927-3.8581 41.419-.5893 61.7488 3.5722 26.3543 5.3957 52.6906 12.4053 76.3444 25.2061 23.6548 12.8002 44.6303 31.9068 55.5918 56.4596 12.4597 27.9075 11.0149 59.9365 6.8229 90.2155-3.9044 28.2027-8.8627 49.3507-29.2872 71.0122z" />,
  ]

  const sync = (oldIdx, newIdx) => {
    const { current: bgSlick } = slider1 || {}
    const { current: answersSlick } = slider2 || {}

    if (bgSlick) {
      bgSlick.slickGoTo(newIdx)
    }

    if (answersSlick) {
      const {
        innerSlider: { list },
      } = answersSlick
      const answersSlider = list.parentNode
      const nextSlide = list.querySelector(
        `.slick-slide[data-index="${newIdx}"]`
      )
      answersSlider.style.height = `${answersSlider.offsetHeight}px`
      requestAnimationFrame(() => {
        answersSlider.style.height = nextSlide && `${nextSlide.scrollHeight}px`
        setTimeout(() => {
          answersSlider.style.height = null
        }, 450)
      })
    }

    setCurrent(newIdx)
  }

  const prevNext = (delta) => {
    const { current: answersSlick } = slider2 || {}
    const next =
      answersSlick && answersSlick[delta < 0 ? 'slickPrev' : 'slickNext']
    if (next && !sliding) {
      setSliding(true)
      next()
      setTimeout(
        () => {
          setSliding(false)
        },
        window.innerWidth >= 900 ? 650 : 450
      )
    }
  }

  const cleanup = () => {
    if (dragger) {
      dragger.destroy()
    }
  }

  useEffect(() => {
    if (body.current && slider2.current && !dragger) {
      // componentDidMount
      const { current: slick } = slider2
      setDragger(
        new Dragger({
          el: body.current,
          onDrag: () => setDragging(true),
          onRelease: ({ xDistance }) => {
            const threshold = 50
            if (xDistance >= threshold) {
              slick.slickPrev()
            } else if (xDistance <= threshold * -1) {
              slick.slickNext()
            }
            setDragging(false)
          },
        })
      )
    }
    return cleanup // componentWillUnmount
  }, [body.current, slider2.current])

  const itemClick = (slug) => {
    setAnswers(step - 1, slug, { advance: true })
  }

  return (
    <>
      {!!answers && answers.length > 0 && (
        <BgSlider
          as={Slider}
          ref={slider1}
          {...{
            arrows: false,
            draggable: false,
            easing: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
            infinite: false,
            initialSlide: rtl ? answers.length : 0,
            responsive: [
              {
                breakpoint: 899,
                settings: {
                  easing: 'ease',
                  speed: 400,
                },
              },
            ],
            rtl: false,
            slidesToScroll: 1,
            slidesToShow: 1,
            speed: 600,
          }}>
          {answers.map(({ bgColor, image, slug }, i) => {
            const {
              portrait: {
                file: {
                  details: { image: { width: imgWidth = 1000 } = {} } = {},
                  url: bgImg,
                } = {},
              } = {},
            } = image || {}
            return (
              <BgSlide
                bgColor={bgColor}
                bgImg={bgImg}
                bgSize={imgWidth}
                key={`${slug}${i.toString()}`}
              />
            )
          })}
          {hasOverview && <BgSlide />}
        </BgSlider>
      )}
      <FlexCenter>
        <Wrapper
          ref={body}
          remaining={rtl ? current : answers.length - current}>
          <Inner>
            {pager}
            <Heading>
              {!!heading.eyebrow && <Eyebrow>{heading.eyebrow}</Eyebrow>}
              {!!heading.headline && <Question>{heading.headline}</Question>}
            </Heading>
            <Arrow
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 69 7"
              width="69"
              height="7">
              <path d="m66.3 3-1.6-1.7.6-.6 2.9 2.8-2.9 2.8-.6-.6 1.6-1.7h-66.3v-1z" />
            </Arrow>
            {!!answers && answers.length > 0 && (
              <AnswerSliderWrap>
                <AnswerSlider
                  as={Slider}
                  ref={slider2}
                  dragging={dragging}
                  onOverview={onOverview}
                  {...{
                    arrows: false,
                    beforeChange: sync,
                    dots: true,
                    draggable: false,
                    infinite: false,
                    initialSlide: rtl ? answers.length : 0,
                    rtl: false,
                    slidesToShow: 1,
                    speed: 400,
                  }}>
                  {answers.map(({ answer, cirColor, slug }, i) => (
                    <AnswerSlide dir={dir} key={`${slug}${i.toString()}`}>
                      <Item onClick={() => itemClick(slug)}>
                        <Blob cirColor={cirColor}>{blobs[i % 4]}</Blob>
                        <ItemText>{answer}</ItemText>
                      </Item>
                    </AnswerSlide>
                  ))}
                  {hasOverview && (
                    <div dir={dir}>
                      <OnboardingQOverview
                        {...{ answers, dragging, itemClick, onOverview }}
                      />
                    </div>
                  )}
                </AnswerSlider>
                <SlickArrow
                  dir="prev"
                  disabled={current === 0 || sliding}
                  greyed={current === 0}
                  onClick={() => prevNext(-1)}
                />
                <SlickArrow
                  dir="next"
                  disabled={current >= answers.length || sliding}
                  greyed={current >= answers.length}
                  onClick={() => prevNext(1)}
                />
              </AnswerSliderWrap>
            )}
          </Inner>
        </Wrapper>
      </FlexCenter>
    </>
  )
}

OnboardingQCarousel.propTypes = {
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      answer: PropTypes.string,
      bgColor: PropTypes.string,
      cirColor: PropTypes.string,
      image: PropTypes.shape({
        portrait: PropTypes.shape({
          file: PropTypes.shape({
            details: PropTypes.shape({
              image: PropTypes.shape({
                height: PropTypes.number,
                width: PropTypes.number,
              }),
            }),
            url: PropTypes.string,
          }),
        }),
      }),
      slug: PropTypes.string,
      thumbnail: PropTypes.shape({
        portrait: PropTypes.shape({
          fixed: PropTypes.shape({
            src: PropTypes.string,
          }),
        }),
      }),
    })
  ),
  eyebrow: PropTypes.string,
  headline: PropTypes.string,
  locale: PropTypes.string,
  overviewEyebrow: PropTypes.string,
  overviewHeadline: PropTypes.string,
  pager: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  setAnswers: PropTypes.func,
  step: PropTypes.number,
}

OnboardingQCarousel.defaultProps = {
  answers: [],
  eyebrow: null,
  headline: null,
  locale: null,
  overviewEyebrow: null,
  overviewHeadline: null,
  pager: null,
  setAnswers: () => {},
  step: 0,
}

export default OnboardingQCarousel
