import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Facebook from './Facebook'
import Twitter from './Twitter'

const RTL_LANGUAGES = ['ar', 'az', 'he', 'fa', 'ur']

const SEO = ({
  title,
  description,
  pathname,
  image,
  locale,
  article,
  twitter,
  facebook,
  revision,
  hideFromSearchEngine,
  valueRetailPage,
  defaultLocale,
}) => {
  return (
    <StaticQuery
      query={graphql`
        query SEOQuery {
          site {
            siteMetadata {
              siteUrl
            }
          }
        }
      `}
      render={(data) => {
        const {
          site: {
            siteMetadata: { siteUrl },
          },
        } = data
        const seo = {
          title,
          description,
          locale,
          article,
          image: `${siteUrl}${image}`,
          defaultLocale,
          url: [
            (valueRetailPage
              ? 'https://www.valueretail.com/'
              : siteUrl
            ).replace(/\/$/, ''),
            (pathname || '').replace(/(^\/|\/$)/g, ''),
          ].join('/'),
          lang: locale,
        }
        const htmlDir = RTL_LANGUAGES.includes(seo.lang) ? 'rtl' : 'ltr'

        return (
          <>
            <Helmet title={seo.title}>
              <html lang={seo.lang} dir={htmlDir} />
              <meta name="description" content={seo.description} />
              <meta name="image" content={seo.image} />
              {hideFromSearchEngine && (
                <meta name="robots" content="noindex,nofollow" />
              )}
              <link rel="canonical" href={seo.url} />
              {revision && <meta name="revision" content={revision} />}
            </Helmet>
            {facebook && (
              <Facebook
                desc={seo.description}
                image={seo.image}
                title={seo.title}
                type={seo.article ? 'article' : 'website'}
                url={seo.url}
                locale={seo.lang}
                name={facebook}
              />
            )}
            {twitter && (
              <Twitter
                title={seo.title}
                image={seo.image}
                desc={seo.description}
                username={twitter}
              />
            )}
          </>
        )
      }}
    />
  )
}

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.string,
  locale: PropTypes.string,
  twitter: PropTypes.string,
  facebook: PropTypes.string,
  revision: PropTypes.string,
  hideFromSearchEngine: PropTypes.bool,
  valueRetailPage: PropTypes.bool,
  defaultLocale: PropTypes.string,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: null,
  locale: null,
  twitter: null,
  facebook: null,
  revision: null,
  hideFromSearchEngine: false,
  valueRetailPage: false,
  defaultLocale: 'en',
}

export default SEO
