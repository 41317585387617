import { rem } from 'polished'

export default [
  '0', // 0
  rem('10px'), // 1
  rem('15px'), // 2
  rem('18px'), // 3
  rem('20px'), // 4
  rem('25px'), // 5
  rem('30px'), // 6
  rem('36px'), // 7
  rem('45px'), // 8
  rem('48px'), // 9
  rem('50px'), // 10
  rem('60px'), // 11
  rem('88px'), // 12
]
