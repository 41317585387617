import styled, { keyframes } from 'styled-components'
import { InlineButton } from '../Onboarding/Onboarding.style'

const Spin = keyframes`
  100% { transform: rotate(360deg) }
`

export const Wrapper = styled(InlineButton)`
  bottom: 0;
  display: block;
  position: fixed;
  right: 0;
  z-index: 1;

  .memDays-trigger-spin {
    animation: ${Spin} 4s linear infinite;
  }
`

export default { Wrapper }
