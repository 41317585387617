import styled from 'styled-components'
import theme from 'styles/theme'
import { themeGet } from 'styled-system'

export const SocialSharingWrapper = styled.ul`
  padding: ${theme.space[6]} 0 0;
  margin: 0 auto;
  display: flexbox;
  list-style: none;
  justify-content: center;
  border-top: solid 1px ${themeGet('colors.sagelight')};
  width: 300px;
`

export const SocialSharingItem = styled.li`
  margin: 0 ${theme.space[2]};
  width: 40px;
  height: 40px;
  position: relative;

  & > a {
    position: absolute;
    left: 0;
  }
`

export default {
  SocialSharingWrapper,
  SocialSharingItem,
}
