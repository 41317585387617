import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import MarkdownRenderer from 'components/MarkdownRenderer'
import {
  Actions,
  Body,
  Cta,
  Eyebrow,
  Image,
  Picture,
  Wrapper,
} from './Content.style'

const Content = ({ bodyCopy, ctaCopy, ctaUrl, eyebrow, image }) => {
  return (
    <Wrapper>
      <Eyebrow>{eyebrow}</Eyebrow>
      {!!bodyCopy && <Body as={MarkdownRenderer} html={bodyCopy} />}
      {!!image && !!(image.portrait || image.landscape).gatsbyImageData && (
        <Picture>
          <Image
            image={
              image.landscape.gatsbyImageData || image.portrait.gatsbyImageData
            }
            alt={image.altText || ''}
          />
        </Picture>
      )}
      {!!ctaCopy && !!ctaUrl && (
        <Actions>
          <Button as={Cta} to={ctaUrl} level="primary">
            {ctaCopy}
          </Button>
        </Actions>
      )}
    </Wrapper>
  )
}

Content.propTypes = {
  bodyCopy: PropTypes.string,
  ctaCopy: PropTypes.string,
  ctaUrl: PropTypes.string,
  eyebrow: PropTypes.string,
  image: PropTypes.shape({
    altText: PropTypes.string,
    landscape: PropTypes.shape({
      gatsbyImageData: PropTypes.shape({
        src: PropTypes.string,
        srcWebp: PropTypes.string,
      }),
    }),
    portrait: PropTypes.shape({
      gatsbyImageData: PropTypes.shape({
        src: PropTypes.string,
        srcWebp: PropTypes.string,
      }),
    }),
  }),
}

Content.defaultProps = {
  bodyCopy: null,
  ctaCopy: null,
  ctaUrl: null,
  eyebrow: null,
  image: null,
}

export default Content
