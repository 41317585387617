import styled from 'styled-components'
import { width, height, display, color, space } from 'styled-system'
import { verticalAlign, float, transform } from 'styles/sharedStyle'

const IconWrapper = styled.span`
  ${color}
  ${width}
  ${height}
  ${display}
  ${transform}
  ${verticalAlign}
  ${float}
  ${space}
  pointer-events: none;
`

IconWrapper.defaultProps = {
  display: 'inline-block',
}

export default IconWrapper
