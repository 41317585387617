import React from 'react'
import PropTypes from 'prop-types'
import theme from 'styles/theme'
import IconWrapper from './IconWrapper'

const ExternalLinkIcon = ({ color, width, display, verticalAlign, px }) => {
  const size = typeof width === 'string' ? width : '100%'
  return (
    <IconWrapper
      width={width}
      display={display}
      color={color}
      verticalAlign={verticalAlign}
      px={px}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        style={{ display: 'block' }}>
        <path
          d="M10,8.5h1v3.6H3V4h5v1H4v6.1h6V8.5z M12,4.6l-4,4L7.3,7.9L11.2,4H9V3h4v4h-1V4.6z"
          fill={color}
        />
      </svg>
    </IconWrapper>
  )
}

ExternalLinkIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  display: PropTypes.string,
  verticalAlign: PropTypes.string,
  px: PropTypes.string,
}

ExternalLinkIcon.defaultProps = {
  color: theme.colors.coaldark,
  width: [],
  display: 'inline-block',
  verticalAlign: 'middle',
  px: '0',
}

export default ExternalLinkIcon
