import React from 'react'
import PropTypes from 'prop-types'
import theme from 'styles/theme'
import IconWrapper from './IconWrapper'

const CheckIcon = ({ color, width, display }) => (
  <IconWrapper width={width} display={display}>
    <svg width="100%" height="100%" viewBox="0 0 16 16">
      <polygon
        points="6.1,12.2 2.8,8.9 3.5,8.1 6.1,10.8 12.8,4.1 13.5,4.9"
        fill={color}
      />
    </svg>
  </IconWrapper>
)

CheckIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  display: PropTypes.string,
}

CheckIcon.defaultProps = {
  color: theme.colors.coaldark,
  width: [],
  display: 'inline-block',
}

export default CheckIcon
