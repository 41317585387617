/* eslint-disable */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import {
  wrapPageElement as pwrap,
  wrapRootElement as rwrap,
} from './gatsby-wrap-page-element'
import trackGTM from './src/utils/trackGTM'
import ReactDOM from 'react-dom'

export const wrapPageElement = pwrap
export const wrapRootElement = rwrap

export const onClientEntry = () => {
  window.onload = () => {
    document.addEventListener('click', function(e) {
      if (
        // @ts-ignore
        e.target.tagName === 'BUTTON' &&
        // @ts-ignore
        e.target.className.includes('evidon')
      ) {
        trackGTM(
          'cookie banner',
          'cookie banner',
          'cookie banner click',
          // @ts-ignore
          e.target.textContent
        )
      }
    })
  }
}

// this is a hack to fix missing styles on refresh in production
// reference: https://github.com/gatsbyjs/gatsby/issues/17676#issuecomment-535796737

// this workaround causes issues on V4 as user can notice visuall the re-render
// export function replaceHydrateFunction() {
//   return (element, container, callback) => {
//     ReactDOM.render(element, container, callback)
//   }
// }

console.info('Revision:', process.env.REVISION)
