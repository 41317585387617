import styled from 'styled-components'
import theme from 'styles/theme'
import { themeGet } from 'styled-system'
import { Container } from 'styles/sharedStyle'

export const ButtonPromoOuter = styled(Container)`
  position: relative;

  ${theme.mediaQueries.large} {
    padding: 0 ${theme.space[1]};
  }
`

export const ButtonPromoWrapper = styled.div`
  color: ${theme.colors.sagedark};
  width: 100%;
  display: block;
  background: ${(props) =>
    props.background === 'Grey'
      ? themeGet('colors.sagelight')
      : themeGet('colors.white')};

  > div {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0 auto;
    padding: 0 ${theme.space[3]};

    ${(props) =>
      props.inFooter
        ? `width: 100%; text-align: left;`
        : `width: 90%; text-align: center;`}

    ${theme.mediaQueries.medium} {
      padding: 0 ${theme.space[3]};
    }

    h2 {
      margin: 0;
    }

    button,
    a {
      margin: 0 auto;
      width: 100%;

      ${theme.mediaQueries.small} {
        width: auto;
      }
    }

    ${(props) =>
      props.inFooter
        ? `
      h2, p, button, a {
        text-align: left;
        margin-left: 0;
        display: block;
      }
      `
        : `
      max-width: 780px;
    `}
  }
`

export const BodyCopy = styled.div`
  color: ${theme.colors.sagemedium};
  text-align: inherit;
`
