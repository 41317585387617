import styled from 'styled-components'
import theme from 'styles/theme'
import { tallDesktop } from './Onboarding.style'

export const Wrapper = styled.div`
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`

export const FlexPane = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: calc(100% - 80px);
  padding: 80px 0 0;

  ${theme.mediaQueries.medium} {
    min-height: calc(100% - 120px);
    padding: 120px 0 0;
  }
`

export const Inner = styled.div`
  min-height: 540px;
  width: 100%;

  ${tallDesktop} {
    min-height: 700px;
  }
`
