import React from 'react'
import PropTypes from 'prop-types'
import Link from 'components/Link'
import { Close, Logo, Pager, Wrapper } from './Header.style'

const Header = ({
  closeClick,
  closeUrl,
  homeUrl,
  logo,
  pagerProps,
  showPager,
  ...props
}) => {
  const logoSize = (() => {
    const match = logo && logo.match(/viewBox="0 0 (\d+ \d+)"/)
    return match && match[1] && match[1].split(' ')
  })()

  return (
    <Wrapper {...props}>
      {!!homeUrl && (
        <Logo
          to={homeUrl}
          logoSize={logoSize}
          hide={showPager.toString()}
          dangerouslySetInnerHTML={{ __html: logo }}
        />
      )}
      {!!pagerProps && showPager && <Pager {...pagerProps} position="header" />}
      <Close
        {...(closeUrl ? { as: Link, to: closeUrl } : { onClick: closeClick })}
      />
    </Wrapper>
  )
}

Header.propTypes = {
  closeClick: PropTypes.func,
  closeUrl: PropTypes.string,
  homeUrl: PropTypes.string,
  logo: PropTypes.string,
  pagerProps: PropTypes.instanceOf(Object),
  showPager: PropTypes.bool,
}

Header.defaultProps = {
  closeClick: () => {},
  closeUrl: null,
  homeUrl: null,
  logo: null,
  pagerProps: null,
  showPager: false,
}

export default Header
