import axios from 'axios'

const getEmergencyBanner = (village, villageServicesEndPoint) =>
  axios
    .get(`${villageServicesEndPoint}/notification/${village}`)
    .then((response) => {
      return response && response.data
    })
    .catch(() => {
      return null
    })

export default getEmergencyBanner
