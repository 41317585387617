import React from 'react'
import PropTypes from 'prop-types'
import {
  HeaderVillageLinkActive,
  HeaderVillageText,
  HeaderVillageCityText,
  HeaderVillageItem,
  HeaderVillageStatusText,
} from './HeaderVillageSelector.style'

const HeaderVillageSelectorItem = ({
  showSkinny,
  showDark,
  navOpen,
  shouldForceSandwichMenu,
  village,
}) => {
  // Preparing villageLink Props
  const villageLinkProps = {
    to: `${village.slug}/${village.node_locale}`,
    color: [showDark || navOpen ? 'slate' : 'white', null, null],
    // z: true, /* produces error Bolean invalid vaue @Fernando - is this needed? */
  }

  if (!shouldForceSandwichMenu) {
    villageLinkProps.color[2] = showSkinny || showDark ? 'slate' : 'white'
  }

  // Preparing villageText Props
  const villageTextProps = {
    fontSize: {
      default: '12px',
    },
    textTransform: {
      default: 'uppercase',
    },
    fontFamily: {
      default: 'BrownStd-Regular, sans-serif',
    },
    letterSpacing: {
      default: '1px',
    },
    lineHeight: {
      default: '1',
      sm: '1',
      md: '1',
      lg: '1',
      xl: '1',
    },
    fontWeight: {
      default: 'normal',
    },
  }

  // Preparing villageItem Props
  const villageItemProps = {
    as: 'li',
    p: ['15px 15px'],
    textAlign: 'center',
    display: 'block',
  }
  if (!shouldForceSandwichMenu) {
    // @ts-ignore
    villageItemProps.display = village ? ['block', 'block', 'block'] : 'block'
  }

  const content = (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {village.city ? (
        <HeaderVillageCityText {...villageTextProps}>
          {village.city}
        </HeaderVillageCityText>
      ) : null}
      <HeaderVillageText {...villageTextProps}>
        {village.name}
      </HeaderVillageText>
      {village.openingStatusLabel ? (
        <HeaderVillageStatusText {...villageTextProps}>
          {village.openingStatusLabel}
        </HeaderVillageStatusText>
      ) : null}
    </div>
  )

  return (
    <HeaderVillageItem
      display={village ? ['block', 'block', 'block'] : 'block'}
      key={`${village.slug}`}
      {...villageItemProps}>
      {village ? (
        <HeaderVillageLinkActive {...villageLinkProps}>
          {content}
        </HeaderVillageLinkActive>
      ) : null}
    </HeaderVillageItem>
  )
}

HeaderVillageSelectorItem.propTypes = {
  village: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
    node_locale: PropTypes.string,
    city: PropTypes.string,
    openingStatusLabel: PropTypes.string,
  }).isRequired,
  showSkinny: PropTypes.bool.isRequired,
  showDark: PropTypes.bool.isRequired,
  navOpen: PropTypes.bool.isRequired,
  shouldForceSandwichMenu: PropTypes.bool.isRequired,
  closeNav: PropTypes.func.isRequired,
}

export default HeaderVillageSelectorItem
