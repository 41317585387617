import React from 'react'
import PropTypes from 'prop-types'
import theme from 'styles/theme'
import IconWrapper from './IconWrapper'

const SearchIcon = ({
  className,
  color,
  width,
  height,
  display,
  transform,
}) => (
  <IconWrapper
    className={className}
    width={width}
    height={height}
    display={display}
    transform={transform}>
    <svg width="100%" height="100%" viewBox="0 0 40 40">
      <g
        transform="translate(2 2)"
        stroke={color}
        strokeWidth="4"
        fill="none"
        fillRule="evenodd">
        <circle cx="16" cy="16" r="16" />
        <path d="M28 28l8 8" />
      </g>
    </svg>
  </IconWrapper>
)

SearchIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  display: PropTypes.string,
  transform: PropTypes.string,
}

SearchIcon.defaultProps = {
  className: null,
  color: theme.colors.coaldark,
  width: [],
  height: [],
  display: 'inline-block',
  transform: 'rotate(0)',
}

export default SearchIcon
