import styled, { keyframes } from 'styled-components'
import easing from 'styles/easing'
import theme from 'styles/theme'
import { Header as SharedHeader } from 'components/MemorableDays'

const Appear = keyframes`
  0% {
  	opacity: 0;
  	transform: scale(.95);
  }
`

export const Wrapper = styled.div`
  animation: ${Appear} 0.5s ${easing.easeOutQuart};
  background-color: ${theme.colors.coaldark};
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
`

export const Header = styled(SharedHeader)`
  flex: none;
`

export const Body = styled.div`
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  justify-content: center;
`
