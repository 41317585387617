const fontSizes = [
  '0.5rem', // 8px
  '0.625rem', // 10px
  '0.75rem', // 12px
  '0.875rem', // 14px
  '1rem', // 16px
  '1.25rem', // 20px
  '1.625rem', // 26px
  '2rem', // 32px
  '2.625rem', // 42px
  '3.75rem', // 60px
  '6.25rem', // 100px
]

export default fontSizes
