import React from 'react'
import PropTypes from 'prop-types'
import { Link as GatsbyLink } from 'gatsby'
import { ExternalLinkIcon } from 'components/Icons'
import theme from 'styles/theme'

const Link = ({ to, external, children, showIcon, ...props }) => {
  let linkComponent = <a {...props}>{children}</a>

  if (to) {
    if (external || to.indexOf('http') === 0) {
      linkComponent = (
        <>
          <a href={to} {...props} target="_blank" rel="noreferrer noopener">
            {children}
          </a>
          {showIcon && (
            <ExternalLinkIcon
              width="16px"
              color={theme.colors.sagemedium}
              verticalAlign="text-bottom"
              px="5px"
            />
          )}
        </>
      )
    } else {
      let newTo = to
      if (to[0] !== '/') {
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line
          console.log(
            `[gatsby-link] Path ${to} is a relative link converted to an absolute path.`
          )
        }

        newTo = `/${to}`
      }

      linkComponent = (
        <GatsbyLink to={newTo} {...props}>
          {children}
        </GatsbyLink>
      )
    }
  }

  return linkComponent
}

Link.propTypes = {
  to: PropTypes.string,
  external: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  showIcon: PropTypes.bool,
}

Link.defaultProps = {
  to: '',
  external: false,
  showIcon: false,
}

export default Link
