import styled from 'styled-components'
import {
  position,
  background,
  height,
  width,
  top,
  zIndex,
  margin,
  maxWidth,
} from 'styled-system'
import { Flex } from 'rebass'

import { transform } from 'styles/sharedStyle'

export const HeaderElement = styled(Flex)`
  ${position}
  ${top}
  ${background}
  ${zIndex}
  ${transform}
  ${height}
  flex-direction: column;
  transition: background .5s ease-in-out, transform .4s ease-in-out;
  will-change: transform, background;
`
export const HeaderWrapper = styled(Flex)`
  ${height}
  ${width}
  ${maxWidth}
  ${margin}
  ${position}
`
export const HeaderBackground = styled(Flex)`
  ${height}
  ${width}
  ${position}
  ${top}
  ${transform}
  transition: transform .5s ease-in-out, background .5s ease-in-out, height .4s ease-in-out;
  will-change: height, background, transform;
`
export default {
  HeaderElement,
  HeaderWrapper,
  HeaderBackground,
}
