import styled from 'styled-components'
import { space } from 'styled-system'
import { rem } from 'polished'
import theme from 'styles/theme'

const {
  title70,
  title60,
  title42,
  title32,
  title26,
  title20,
  body16,
} = theme.textStyles

export const Wrapper = styled.div`
  ${space}
  text-align: left;

  html[dir='rtl'] & {
    text-align: right;
  }

  h1 {
    ${title70}
    font-size: ${rem(42)};

    ${theme.mediaQueries.large} {
      font-size: ${rem(70)};
    }
  }

  h2 {
    ${title60}
    font-size: ${rem(32)};

    ${theme.mediaQueries.large} {
      font-size: ${rem(60)};
    }
  }

  h3 {
    ${title42}
    font-size: ${rem(26)};

    ${theme.mediaQueries.large} {
      font-size: ${rem(42)};
    }
  }

  h4 {
    ${title32}
    font-size: ${rem(26)};

    ${theme.mediaQueries.large} {
      font-size: ${rem(32)};
    }
  }

  h5 {
    ${title26}
    font-size: ${rem(26)};
  }

  h6 {
    ${title20}
    font-size: ${rem(20)};
  }

  p,
  ul,
  ol {
    ${body16}
    font-size: ${rem(16)};
  }

  li > p {
    font-size: inherit !important;
  }

  a {
    text-decoration: underline;
  }
`

export default Wrapper
