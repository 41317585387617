import { TransitionGroup } from 'react-transition-group'
import styled from 'styled-components'
import theme from 'styles/theme'
import Button from 'components/Button'
import Link from 'components/Link'

export const tallDesktop = `${theme.mediaQueries.medium} and (min-height: 840px)`

export const Wrapper = styled.div`
  background: none 50% 50% / cover ${theme.colors.coaldark};
  height: 100%;
  left: 0;
  min-height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 110;

  ${({ background: [mobile, desktop] = [] }) =>
    (!!mobile || !!desktop) &&
    `
    background-image: url(${mobile || desktop});

    ${theme.mediaQueries.medium} {
      background-image: url(${desktop || mobile});
    }
  `}

  &.fade-enter {
    background-color: transparent;
    background-size: 0 0;

    &::before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      right: 50px;
      bottom: 50px;
    }

    > :first-child {
      opacity: 0;
    }
  }

  &.fade-enter-active {
    background: none;

    &::before {
      background: none 50% 50% / cover fixed ${theme.colors.coaldark};
      border-radius: 100%;
      content: '';
      display: block;
      width: 200vh;
      height: 200vh;
      position: absolute;
      right: -50%;
      bottom: -50%;
      transition-property: height, right, bottom, width;
      transition-duration: 0.5s;
      transition-timing-function: ease-in;

      ${({ background: [mobile, desktop] = [] }) =>
        (!!mobile || !!desktop) &&
        `
        background-image: url(${mobile || desktop});

        ${theme.mediaQueries.medium} {
          background-image: url(${desktop || mobile});
        }
      `}

      ${theme.mediaQueries.small} {
        width: 200vw;
        height: 200vw;
      }
    }

    > :first-child {
      opacity: 1;
      transition: opacity 0.3s ease 0.3s;
    }
  }

  &.fade-exit {
    opacity: 1;
  }

  &.fade-exit-active,
  &.fade-exit-done {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  h2,
  h3 {
    color: inherit;
  }
`

export const InlineButton = styled.button`
  appearance: none;
  background: none;
  border-radius: 0;
  border: 0 none;
  box-shadow: none;
  color: inherit;
  outline: none;
  padding: 0;
  text-align: inherit;
  white-space: normal;
`

export const Head = styled.div`
  align-items: center;
  box-sizing: content-box;
  color: ${theme.colors.white};
  display: flex;
  height: 40px;
  justify-content: space-between;
  padding: 20px 30px 20px 20px;
  position: relative;
  z-index: 1;

  ${theme.mediaQueries.medium} {
    height: 60px;
    padding: 30px;
  }
`

export const Logo = styled(Link)`
  display: block;
  flex: none;

  ${({ hide }) =>
    hide === 'true' &&
    `
    ${theme.mediaQueries.smallOnly} {
      display: none;
    }
  `}

  ${({ logoSize }) => {
    const [width = 60, height = 60] = logoSize || []
    return `
      svg {
        display: block;
        fill: ${theme.colors.white};
        height: ${height / 1.5}px;
        width: ${width / 1.5}px;

        ${theme.mediaQueries.medium} {
          height: ${height}px;
          width: ${width}px;
        }
      }
    `
  }}
`

export const Pager = styled.div`
  background: linear-gradient(
      ${theme.colors.sagelight},
      ${theme.colors.sagelight}
    )
    50% 50% / 15px 1px no-repeat ${theme.colors.white};
  border-radius: 100%;
  color: ${theme.colors.sagedark};
  display: flex;
  flex: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  height: 40px;
  transform: rotate(-45deg);
  width: 40px;

  ${({ position }) =>
    position === 'header'
      ? `
    ${theme.mediaQueries.medium} {
      display: none;
    }
  `
      : position === 'body' &&
        `
    margin-top: -40px;
    position: relative;
    top: -58px;

    ${theme.mediaQueries.smallOnly} {
      display: none;
    }
  `}

  &::before,
  &::after {
    padding: 3px 0;
    transform: rotate(45deg);
  }

  &::before {
    content: attr(data-step);
  }

  &::after {
    content: attr(data-total);
  }
`

export const Close = styled(InlineButton)`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: none;
  height: 24px;
  justify-content: center;
  width: 24px;

  ${theme.mediaQueries.medium} {
    align-self: flex-start;
    height: 36px;
    width: 36px;
  }

  &::before {
    background: linear-gradient(currentColor, currentColor) 50% 50% / 1px 100%
        no-repeat,
      linear-gradient(currentColor, currentColor) 50% 50% / 100% 1px no-repeat;
    content: '';
    display: block;
    height: 19px;
    position: relative;
    transform: rotate(45deg);
    width: 19px;

    ${theme.mediaQueries.medium} {
      height: 29px;
      width: 29px;
    }
  }
`

export const ConfirmBtn = styled.span`
  cursor: pointer;
  text-decoration: underline;
`

export const ConfirmModal = styled.div`
  align-items: center;
  background: rgba(16, 16, 16, 0.95);
  color: ${theme.colors.white};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
`

export const ConfirmModalBtn = styled(Button)`
  flex: none;
  padding: 20px;
  width: 224px;

  &:nth-child(n + 2) {
    border-top: 0 none;
  }

  ${({ active }) =>
    !active &&
    `
    background: transparent;
  `}
`

export const StepTransitionGroup = styled(TransitionGroup)`
  height: 100%;
  position: relative;
  width: 100%;
`

export const StepWrapper = styled.div`
  height: 100%;
  position: relative;
  width: 100%;

  &.step-enter {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    ${({ direction }) => `
      transform: translateY(${direction < 0 ? '-150%' : '150%'});
    `}
  }

  &.step-enter-active,
  &.step-enter-done {
    transform: translateY(0);
    transition: transform 1s cubic-bezier(0.77, 0, 0.175, 1);
  }

  &.step-exit {
    transform: translateY(0);
  }

  &.step-exit-active,
  &.step-exit-done {
    transition: transform 1s cubic-bezier(0.77, 0, 0.175, 1);
  }

  &.step-enter,
  &.step-enter-active,
  &.step-enter-done {
    ~ .step-exit-active,
    ~ .step-exit-done {
      ${({ direction }) => `
        transform: translateY(${direction < 0 ? '150%' : '-150%'});
      `}
    }
  }

  &.step-exit-done {
    visibility: hidden;
  }
`
