/* eslint-disable no-unused-expressions */
import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ChevronIcon from 'components/Icons/ChevronIcon'
import { rem } from 'polished'
import {
  HeaderVillageSelector,
  HeaderVillageText,
  HeaderVillageOptions,
  HeaderVillageOptionsContainer,
  HeaderVillageItem,
  HeaderVillageButton,
  HeaderVillageSelectorOverlay,
} from './HeaderVillageSelector.style'
import HeaderVillageSelectorItem from './HeaderVillageSelectorItem'

const VillageSelector = ({
  label,
  villageSelectorOpen,
  closeVillageSelector,
  villageHandler,
  shouldShowSmallerFonts,
  closeNav,
  villages,
  showSkinny,
  showDark,
  navOpen,
  shouldForceSandwichMenu,
  pathname,
  display,
  forceWhiteLabels,
}) => {
  const villagesRef = useRef(null)
  const [villagesOffsetTop, setVillagesOffsetTop] = useState(null)
  const [windowHeight, setwindowHeight] = useState(null)

  const color = showSkinny || showDark || navOpen ? 'slate' : 'white'

  const displayProp = 'block'
  const displayPropLg = 'flex'

  const updateHeight = () => {
    setwindowHeight(window.innerHeight)
    if (villagesRef.current) {
      setVillagesOffsetTop(villagesRef.current.offsetTop)
    }
  }

  useEffect(() => {
    if (villagesRef.current) {
      setVillagesOffsetTop(villagesRef.current.offsetTop)
    }
  }, [villagesRef, villageSelectorOpen])

  useEffect(() => {
    updateHeight()
    window.addEventListener('resize', updateHeight)
    return () => window.removeEventListener('resize', updateHeight)
  }, [])

  // Preparing villageSelector Props
  const villageSelectorProps = {
    width: ['100%', null, null],
    height: [null, null, null],
    textAlign: ['center'],
    flexDirection: ['column', null, null],
    justifyContent: ['center', null, null],
    display: [displayPropLg, displayProp, displayProp],
    pt: [null, null, null],
    position: ['relative', null, null],
    bottom: [0],
    left: [0],
    pl: [null, null, null],
    ml: [null, null, null],
    mr: [null, null, null],
    bg: ['white', null, null],
    borderTop: [null, null, null],
    shouldForceSandwichMenu,
    villageSelectorOpen,
  }

  // Preparing villageText Props
  const mobileFontSize = shouldShowSmallerFonts ? rem(32) : rem(42)
  const villageTextProps = {
    fontSize: {
      default: mobileFontSize,
    },
    textTransform: {
      default: null,
      sm: null,
      md: null,
      lg: null,
      xl: null,
    },
    fontFamily: {
      default: 'SangbleuKindgdom-Light, serif',
    },
    letterSpacing: {
      default: '-1.2px',
    },
    lineHeight: {
      default: '2',
    },
    fontWeight: {
      default: 'normal',
    },
  }

  // Preparing villageLink Props
  const villageButtonProps = {
    color: [showDark || navOpen ? 'slate' : 'white', null, null],
    fill: color,
    display: ['block', null, null],
  }
  if (villageSelectorOpen) {
    villageButtonProps.display = ['block', null, null]
  }
  if (!shouldForceSandwichMenu) {
    villageButtonProps.color[2] = showSkinny || showDark ? 'slate' : 'white'
    villageButtonProps.color[2] = forceWhiteLabels
      ? 'white'
      : villageButtonProps.color[2]
    villageButtonProps.display[2] = 'block'
  }

  if (!shouldForceSandwichMenu) {
    villageSelectorProps.width[2] = 'auto'
    villageSelectorProps.height[2] = '100%'
    villageSelectorProps.flexDirection[2] = 'row'
    villageSelectorProps.justifyContent[2] = 'flex-start'
    villageSelectorProps.display[2] = displayPropLg
    villageSelectorProps.position[2] = 'relative'
    villageSelectorProps.pl[2] = '20px'
    villageSelectorProps.ml[2] = '20px'
    villageSelectorProps.bg[2] = 'transparent'
    villageSelectorProps.borderTop[2] = 'none'

    // If not a sandwich menu on desktop
    villageTextProps.textTransform = {
      ...villageTextProps.textTransform,
      md: 'uppercase',
      lg: 'uppercase',
      xl: 'uppercase',
    }
    villageTextProps.fontSize = {
      ...villageTextProps.fontSize,
      md: '12px',
      lg: '12px',
      xl: '12px',
    }
    villageTextProps.lineHeight = {
      ...villageTextProps.lineHeight,
      md: '1',
      lg: '1',
      xl: '1',
    }
    villageTextProps.fontFamily = {
      ...villageTextProps.fontFamily,
      md: 'BrownStd-Regular',
      lg: 'BrownStd-Regular',
      xl: 'BrownStd-Regular',
    }
    villageTextProps.letterSpacing = {
      ...villageTextProps.letterSpacing,
      md: '1px',
      lg: '1px',
      xl: '1px',
    }
  }

  // Preparing villageOptions Props
  const villageOptionsProps = {
    as: 'ul',
    padding: [null, null, null],
    position: [null, null, null],
    left: [null, null, null],
    top: [null, null, null],
    bottom: [null, null, null],
    margin: [null, null, null],
    background: [null, null, null],
    display: ['flex', null, null],
    width: ['auto', 'auto', 'auto'],
    flexDirection: ['column', null, null],
    justifyContent: ['space-between'],
    height: [null, null, null],
    opacity: [null, null, null],
    paddingTop: [null, null, '20px'],
    villageSelectorOpen,
    shouldForceSandwichMenu,
  }
  if (villageSelectorOpen) {
    villageOptionsProps.padding = ['40px 60px', null, null]
    villageOptionsProps.opacity = ['1', '1', '1']
    villageOptionsProps.scaleY = '1'

    if (windowHeight && villagesOffsetTop) {
      villageOptionsProps.height = [
        `${windowHeight - villagesOffsetTop}px`,
        null,
        null,
      ]
    }
  }
  useEffect(() => {}, [windowHeight, villagesOffsetTop])
  if (villageSelectorOpen && shouldForceSandwichMenu) {
    villageOptionsProps.position = ['absolute', 'absolute', 'absolute']
    villageOptionsProps.top = ['0', '0', '0']
    villageOptionsProps.background = [
      'rgba(255, 255, 255, 0.9)',
      'rgba(255, 255, 255, 0.9)',
      'white',
    ]
    villageOptionsProps.width = ['100vw', '100vw', 'auto']
  }
  if (!shouldForceSandwichMenu) {
    villageOptionsProps.padding[2] = '40px 60px'
    villageOptionsProps.position[2] = 'absolute'
    villageOptionsProps.left[2] = '-30px'
    villageOptionsProps.top[2] = '100%'
    villageOptionsProps.background[2] = 'white'
    villageOptionsProps.height[2] = 'auto'
  }

  // Preparing villageItem Props
  const villageItemProps = {
    display: ['block', 'block', 'block'],
    p: ['0 0', null, null],
    textAlign: ['center', null, null],
    alignItems: ['center', 'center', 'center'],
    ml: ['25px', null, null],
  }
  if (villageSelectorOpen) {
    villageItemProps.p = ['0', null, null]
  }
  if (!shouldForceSandwichMenu) {
    villageItemProps.display[2] = 'flex'
    villageItemProps.p[2] = '0'
    villageItemProps.textAlign[2] = 'left'
    villageItemProps.ml[2] = '0px'
  }

  // Preparing HeaderVillageSelectorOverlay Props
  const HeaderVillageSelectorOverlayProps = {
    display: [villageSelectorOpen ? 'block' : 'none', null, null],
  }
  if (!shouldForceSandwichMenu) {
    HeaderVillageSelectorOverlayProps.display[2] = 'none'
  }

  const visibility = display ? 'block' : 'none'

  const hasVillagesToChoose = villages?.length > 1

  if (!hasVillagesToChoose) {
    return null
  }

  return (
    <HeaderVillageSelector
      ref={villagesRef}
      display={visibility}
      {...villageSelectorProps}>
      <HeaderVillageItem {...villageItemProps}>
        <HeaderVillageButton
          {...villageButtonProps}
          data-element="villages"
          onClick={villageHandler}>
          <HeaderVillageText data-element="villages" {...villageTextProps}>
            {label}
          </HeaderVillageText>
          <ChevronIcon
            width="7px"
            height="12px"
            color="currentColor"
            transform={
              villageSelectorOpen
                ? 'rotate(90deg) translateY(-4px)'
                : 'rotate(270deg)'
            }
          />
        </HeaderVillageButton>
      </HeaderVillageItem>

      <HeaderVillageOptions {...villageOptionsProps}>
        <HeaderVillageOptionsContainer {...villageOptionsProps}>
          {villages &&
            villages.map((village) => (
              <HeaderVillageSelectorItem
                key={village.slug}
                village={village}
                {...{
                  showSkinny,
                  showDark,
                  navOpen,
                  shouldForceSandwichMenu,
                  closeNav,
                  closeVillageSelector,
                  pathname,
                }}
              />
            ))}
        </HeaderVillageOptionsContainer>
      </HeaderVillageOptions>
      <HeaderVillageSelectorOverlay
        {...HeaderVillageSelectorOverlayProps}
        onClick={closeVillageSelector}
      />
    </HeaderVillageSelector>
  )
}

VillageSelector.propTypes = {
  label: PropTypes.string.isRequired,
  forceWhiteLabels: PropTypes.bool,
  villageHandler: PropTypes.func.isRequired,
  closeVillageSelector: PropTypes.func.isRequired,
  villages: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
    .isRequired,
  shouldShowSmallerFonts: PropTypes.bool.isRequired,
  villageSelectorOpen: PropTypes.bool.isRequired,
  shouldForceSandwichMenu: PropTypes.bool.isRequired,
  showSkinny: PropTypes.bool.isRequired,
  showDark: PropTypes.bool.isRequired,
  navOpen: PropTypes.bool.isRequired,
  navHandler: PropTypes.func.isRequired,
  closeNav: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  translatedSlugs: PropTypes.objectOf(PropTypes.string),
  display: PropTypes.bool.isRequired,
}

VillageSelector.defaultProps = {
  forceWhiteLabels: false,
  translatedSlugs: {},
}

export default VillageSelector
