import React from 'react'
import styled, { css } from 'styled-components'
import {
  color,
  height,
  fontFamily,
  fontWeight,
  position,
  top,
  right,
  bottom,
  left,
  display,
  padding,
  background,
  margin,
  textAlign,
  opacity,
  letterSpacing,
  lineHeight,
  fontSize,
} from 'styled-system'
import { Flex, Box } from 'rebass'

import Link from 'components/Link'
import { CtaLabel } from 'components/Typography'
import { appearingAnimation } from 'styles/animation'
import { fill, textTransform as textTransformStyle } from 'styles/sharedStyle'
import theme from 'styles/theme'

const diamondStyle = css`
  display: inline-block;
  position: relative;
  overflow: visible;
  ::after {
    content: '♦︎';
    display: inline-block;
    font-size: 5px;
    transition: opacity 0.3s ease-in-out;
    right: 0;
    top: 50%;
    opacity: 1;
    position: absolute;
    margin-right: -15px;
    margin-top: -6px;
  }
`

export const HeaderLanguageSelectorOverlay = styled(Flex)`
  ${display}
  position: absolute;
  left: -500%;
  width: 1000%;
  height: 1000%;
  top: calc(-1000% - 1px);
  background: rgba(255, 255, 255, 0.9);
`

export const HeaderLoginWrapper = styled(Flex)`
  ${display}
  ${top}
  ${left}
  ${right}
  ${bottom}
  ${position}
  ${height}
  ${padding}
  transition: height .3s ease-in-out;
  opacity: 0;
  bottom: 0px;
  animation: ${appearingAnimation('50px')} 0.3s ease-out;
  animation-direction: normal;
  animation-delay: 0.3s;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  white-space: nowrap;

  ${(props) =>
    !props.shouldForceSandwichMenu
      ? css`
          @media only screen and (max-width: ${theme.breakpoints[1]}) {
            max-height: 100%;
            overflow: visible;
            ul {
              transition: height 0.3s ease-in-out;
              will-change: height;
            }

            ${!props.languageSelectorOpen &&
              `
              > ul {
                height: 0;
              }
            `}

            :before {
              content: '';
              position: absolute;
              left: -500%;
              width: 1000%;
              height: 1px;
              top: -1px;
              background: ${theme.colors.alto};
            }
          }
          @media only screen and (min-width: ${theme.breakpoints[1]}) {
            ::before {
              content: '';
              display: block;
              position: absolute;
              height: 17px;
              width: 1px;
              background: ${theme.colors.alto};
              left: 0;
              top: 50%;
              margin-top: -6px;
            }
          }
        `
      : css`
          max-height: 50%;
          overflow: visible;
          ul {
            transition: height 0.3s ease-in-out;
            will-change: height;
          }

          ${!props.languageSelectorOpen &&
            `
            > ul {
              height: 0;
            }
          `}

          :before {
            content: '';
            position: absolute;
            left: -500%;
            width: 1000%;
            height: 1px;
            top: -1px;
            background: ${theme.colors.alto};
          }
        `}
`

export const HeaderLanguageOptions = styled(Flex)`
  overflow: auto;
  transform-origin: 50% 0;
  transform: scaleY(0);
  transition: transform 0.3s ease-in;
  transition-delay: 0.3s;
  box-sizing: content-box;
  list-style: none;
  margin: 0;
  padding: 0 60px;
  height: 100px;
  ${position}
  ${left}
  ${top}
  ${background}
  ${opacity}
  ${(props) =>
    props.languageSelectorOpen &&
    `transition-delay: 0s;
    transform: scaleY(${props.scaleY});

  `}

  span {
    opacity: 0;
    transition: opacity 0.3s ease-in;
    transition-delay: 0s;

    ${(props) =>
      props.languageSelectorOpen &&
      `opacity: 1;
      transition-delay: 0.3s;
    `}
  }
`

export const HeaderLoginLink = styled(
  ({ allowDiamond, textTransform, ...rest }) => <Link {...rest} />
)`
  display: block;
  border: 0;
  cursor: pointer;
  ${textTransformStyle}
  ${fontFamily}
  ${fontWeight}
  ${color}
  ${margin}

  * {
    ${fill};
  }
`

export const HeaderLoginLinkActive = styled(
  ({ allowDiamond, textTransform, ...rest }) => <Link {...rest} />
)`
  display: block;
  border: 0;
  cursor: pointer;
  ${textTransformStyle}
  ${fontFamily}
  ${fontWeight}
  ${color}
  ${margin}

  ${(props) => props.allowDiamond && diamondStyle};
`

export const HeaderLanguageButton = styled.div`
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;

  span {
    height: 15px;
    margin-right: 10px;
  }

  span:nth-child(2) {
    position: relative;
    top: 3px;
    margin-right:-5px;
  }

  ${textTransformStyle}
  ${fontFamily}
  ${fontWeight}
  ${color}
  ${margin}
  ${display}
`

export const HeaderLoginLinkItem = styled(Box)`
  ${display}
  ${textAlign}
  ${textTransformStyle}
  ${fontFamily}
  ${fontWeight}
  ${lineHeight}
  ${fontSize}
  ${color}
  ${letterSpacing}
`

export const HeaderLoginText = styled(({ allowDiamond, ...rest }) => (
  <CtaLabel as="span" {...rest} />
))`
  ${(props) => (props.allowDiamond ? diamondStyle : '')};

  @media only screen and (min-width: ${theme.breakpoints[1]}) {
    ::after {
      opacity: 0;
    }
    :hover::after {
      opacity: 1;
    }
  }
`
