/* eslint-disable no-unused-expressions */
import React from 'react'
import PropTypes from 'prop-types'
import theme from 'styles/theme'
import AccountIcon from 'components/Icons/AccountIcon'
import ChevronIcon from 'components/Icons/ChevronIcon'
import {
  HeaderLoginWrapper,
  HeaderLanguageOptions,
  HeaderLoginLinkItem,
  HeaderLanguageButton,
  HeaderLanguageSelectorOverlay,
} from './HeaderLogin.style'
import HeaderLoginItem from './HeaderLoginItem'

const HeaderLogin = ({
  selectedLanguage,
  languageSelectorOpen,
  closeLanguageSelector,
  languageSelectorHandler,
  closeNav,
  languageList,
  signin,
  display,
  showSkinny,
  showDark,
  navOpen,
  shouldForceSandwichMenu,
  forceWhiteLabels,
  villageName,
}) => {
  let color =
    showSkinny || showDark || navOpen ? theme.colors.slate : theme.colors.white
  color = forceWhiteLabels ? 'white' : color

  const displayProp = display ? 'block' : 'none'
  const displayPropLg = display ? 'flex' : 'none'

  // Preparing LanguageSelector Props
  const loginProps = {
    width: ['50%', null, null],
    height: [null, null, null],
    textAlign: ['center'],
    flexDirection: ['column', null, null],
    justifyContent: ['center', null, null],
    display: [displayPropLg, displayProp, displayProp],
    pt: [null, null, null],
    position: ['relative', null, null],
    bottom: [0],
    left: [0],
    pl: [null, null, null],
    ml: [null, null, null],
    mr: [null, null, null],
    bg: ['white', null, null],
    borderTop: ['solid 1px', null, null],
    shouldForceSandwichMenu,
    languageSelectorOpen,
  }

  if (!shouldForceSandwichMenu) {
    loginProps.width[2] = 'auto'
    loginProps.height[2] = '100%'
    loginProps.flexDirection[2] = 'row'
    loginProps.justifyContent[2] = 'flex-start'
    loginProps.display[2] = displayProp
    loginProps.pt[2] = '4px'
    loginProps.position[2] = 'relative'
    loginProps.pl[2] = '20px'
    loginProps.ml[2] = '30px'
    loginProps.bg[2] = 'transparent'
    loginProps.borderTop[2] = 'none'
  }

  // Preparing LanguageLink Props
  const languageButtonProps = {
    color: [
      showDark || navOpen ? theme.colors.slate : theme.colors.white,
      null,
      null,
    ],
    fill: color,
    display: ['block', null, null],
  }
  if (languageSelectorOpen) {
    languageButtonProps.display = ['none', null, null]
  }
  if (!shouldForceSandwichMenu) {
    languageButtonProps.color[2] =
      showSkinny || showDark ? theme.colors.slate : theme.colors.white
    languageButtonProps.display[2] = 'block'
  }

  // Preparing LanguageOptions Props
  const languageOptionsProps = {
    as: 'ul',
    padding: [null, null, null],
    position: [null, null, null],
    left: [null, null, null],
    top: [null, null, null],
    background: [null, null, null],
    display: ['flex', null, null],
    flexDirection: ['column', null, null],
    justifyContent: ['end'],
    height: [null, 'null', 'null'],
    opacity: [null, null, null],
    languageSelectorOpen,
  }
  if (languageSelectorOpen) {
    languageOptionsProps.padding = [null, null, null]
    languageOptionsProps.height = [`${languageList.length * 48}px`, null, null]
    languageOptionsProps.opacity = ['1', '1', '1']
    languageOptionsProps.scaleY = '1'
  }
  if (!shouldForceSandwichMenu) {
    languageOptionsProps.position[2] = 'absolute'
    languageOptionsProps.left[2] = '-54px'
    languageOptionsProps.top[2] = '100%'
    languageOptionsProps.background[2] = 'white'
    languageOptionsProps.height[2] = 'auto'
  }

  // Preparing LanguageItem Props
  const languageItemProps = {
    p: ['15px 0', null, null],
    textAlign: ['center', null, null],
    ml: ['25px', null, null],
  }
  if (languageSelectorOpen) {
    languageItemProps.p = ['0', null, null]
  }
  if (!shouldForceSandwichMenu) {
    languageItemProps.p[2] = '0'
    languageItemProps.textAlign[2] = 'left'
    languageItemProps.ml[2] = '0px'
  }

  // Preparing HeaderLanguageSelectorOverlay Props
  const headerLanguageSelectorOverlayProps = {
    display: [languageSelectorOpen ? 'block' : 'none', null, null],
  }
  if (!shouldForceSandwichMenu) {
    headerLanguageSelectorOverlayProps.display[2] = 'none'
  }

  const languageObj = {}
  languageList.forEach((item) => {
    if (item.locale !== selectedLanguage.locale) {
      languageObj[item.locale] = item
    }
  })

  return (
    <HeaderLoginWrapper {...loginProps}>
      <HeaderLanguageSelectorOverlay
        {...headerLanguageSelectorOverlayProps}
        onClick={closeLanguageSelector}
      />
      <HeaderLoginLinkItem {...languageItemProps}>
        <HeaderLanguageButton
          {...languageButtonProps}
          data-element="login"
          onClick={languageSelectorHandler}>
          <AccountIcon width="15px" color={color} />
          <ChevronIcon
            width="7px"
            height="12px"
            color="currentColor"
            transform={
              languageSelectorOpen
                ? 'rotate(90deg) translateY(-4px)'
                : 'rotate(270deg)'
            }
          />
        </HeaderLanguageButton>
      </HeaderLoginLinkItem>

      <HeaderLanguageOptions {...languageOptionsProps}>
        <HeaderLoginItem
          label={signin.signinLabel}
          href={signin.signinUrl}
          showSkinny={showSkinny}
          showDark={showDark}
          navOpen={navOpen}
          shouldForceSandwichMenu={shouldForceSandwichMenu}
          closeNav={closeNav}
          logInVar="Sign_In"
          villageName={villageName}
        />
        <HeaderLoginItem
          label={signin.signupLabel}
          href={signin.signupUrl}
          showSkinny={showSkinny}
          showDark={showDark}
          navOpen={navOpen}
          shouldForceSandwichMenu={shouldForceSandwichMenu}
          closeNav={closeNav}
          logInVar="Sign_Up"
          villageName={villageName}
        />
      </HeaderLanguageOptions>
    </HeaderLoginWrapper>
  )
}

HeaderLogin.propTypes = {
  forceWhiteLabels: PropTypes.bool,
  languageSelectorHandler: PropTypes.func.isRequired,
  closeLanguageSelector: PropTypes.func.isRequired,
  selectedLanguage: PropTypes.shape({
    label: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
  }).isRequired,
  languageList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  signin: PropTypes.objectOf(PropTypes.string),
  display: PropTypes.bool.isRequired,
  languageSelectorOpen: PropTypes.bool.isRequired,
  shouldForceSandwichMenu: PropTypes.bool.isRequired,
  showSkinny: PropTypes.bool.isRequired,
  showDark: PropTypes.bool.isRequired,
  navOpen: PropTypes.bool.isRequired,
  navHandler: PropTypes.func.isRequired,
  closeNav: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  translatedSlugs: PropTypes.objectOf(PropTypes.string),
  villageName: PropTypes.string,
}

HeaderLogin.defaultProps = {
  forceWhiteLabels: false,
  translatedSlugs: {},
  signin: {},
  languageList: [],
  villageName: '',
}

export default HeaderLogin
