import * as React from 'react'

function Expand(props) {
  return (
    <svg
      width={30}
      height={30}
      viewBox="0 -1 30 30"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g>
        <polygon points="8.65,21.35 8.65,17.35 7.65,17.35 7.65,22.35 12.65,22.35 12.65,21.35 	" />
        <rect
          x="7.42"
          y="18.72"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -10.4382 13.2483)"
          width="6.7"
          height="1"
        />
      </g>
      <g>
        <polygon points="21.35,8.65 21.35,12.65 22.35,12.65 22.35,7.65 17.35,7.65 17.35,8.65 	" />
        <rect
          x="15.87"
          y="10.27"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -1.9884 16.7489)"
          width="6.7"
          height="1"
        />
      </g>
    </svg>
  )
}

export default Expand
