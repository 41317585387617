import React from 'react'
import PropTypes from 'prop-types'
import { FlexPane, Inner, Wrapper } from './FlexCenter.style'

const FlexCenter = ({ children }) => (
  <Wrapper>
    <FlexPane>
      <Inner>{children}</Inner>
    </FlexPane>
  </Wrapper>
)

FlexCenter.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

FlexCenter.defaultProps = {
  children: null,
}

export default FlexCenter
