/** Class that calculates pixel distance dragged over an element. */
class Dragger {
  constructor(params) {
    Object.assign(
      this,
      {
        el: null,
        events: {
          down: 'mousedown',
          move: 'mousemove',
          release: 'mouseup',
        },
        onPress: () => {},
        onDrag: () => {},
        onRelease: () => {},
        x1: null,
        x2: null,
        xDistance: 0,
        y1: null,
        y2: null,
        yDistance: 0,
      },
      params
    )

    this.build()

    return this
  }

  /**
   * Attaches mousedown and touchstart event listeners.
   */
  build() {
    this.el.addEventListener('mousedown', this.onMouseDown)
    this.el.addEventListener('touchstart', this.onTouchStart, { passive: true })
  }

  /**
   * Removes all event listeners and resets computed values to default.
   */
  destroy() {
    this.el.removeEventListener('mousedown', this.onMouseDown)
    this.el.removeEventListener('touchstart', this.onTouchStart)

    document.removeEventListener('mousemove', this.onMove)
    document.removeEventListener('touchmove', this.onMove)

    document.removeEventListener('mouseup', this.onUp)
    document.removeEventListener('touchend', this.onUp)

    this.reset()
  }

  /**
   * Resets computed values to default.
   */
  reset() {
    Object.assign(this, {
      x1: null,
      x2: null,
      xDistance: 0,
      y1: null,
      y2: null,
      yDistance: 0,
    })
  }

  /**
   * Handles mousedown event.
   */
  onMouseDown = () => {
    Object.assign(this.events, {
      down: 'mousedown',
      move: 'mousemove',
      release: 'mouseup',
    })

    this.onDown()
  }

  /**
   * Handles touchstart event.
   */
  onTouchStart = () => {
    Object.assign(this.events, {
      down: 'touchstart',
      move: 'touchmove',
      release: 'touchend',
    })

    this.onDown()
  }

  /**
   * Sets onMove and onUp listeners.
   */
  onDown() {
    const { move, release } = this.events

    document.addEventListener(move, this.onMove)
    document.addEventListener(release, this.onUp)

    // The onPress callback passed in params
    this.onPress(this)
  }

  /**
   * Determines x and y distance of mouse or touch movement.
   * @param {event} The mousemove or touchmove event.
   */
  onMove = (e) => {
    const eventData = e.changedTouches ? e.changedTouches[0] : e
    const { clientX, clientY } = eventData

    if (typeof this.x1 !== 'number') {
      this.x1 = clientX
      this.y1 = clientY
    }

    this.x2 = clientX
    this.xDistance = clientX - this.x1
    this.y2 = clientY
    this.yDistance = clientY - this.y1

    // The onDrag callback passed in params
    this.onDrag(this)
  }

  /**
   * Removes move and release event listeners, and resets x, y, and distance
   * values to default.
   */
  onUp = () => {
    const { move, release } = this.events

    document.removeEventListener(move, this.onMove)
    document.removeEventListener(release, this.onUp)

    // The onRelease callback passed in params
    this.onRelease(this)
    this.reset()
  }
}

export default Dragger
