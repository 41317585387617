import styled from 'styled-components'
import { Flex } from 'rebass'
import theme from 'styles/theme'

import {
  position,
  top,
  right,
  bottom,
  left,
  height,
  display,
  zIndex,
  backgroundColor,
  minHeight,
  width,
} from 'styled-system'

export const HeaderNavElement = styled(Flex)`
  ${top}
  ${left}
  ${right}
  ${bottom}
  ${position}
  ${height}
  ${display}
  ${zIndex}
  ${minHeight}
  ${backgroundColor}
`

export const Wrapper = styled(Flex)`
  ${position}
  ${width}

  align-items: center;
  background-color: white;
  bottom: 0;
  display: flex;
  flex-flow: row;
  justify-content: center;
  left: 0;

  ${theme.mediaQueries.medium} {
    background-color: transparent;
  }
`

export default {
  HeaderNavElement,
  Wrapper,
}
