import React from 'react'
import PropTypes from 'prop-types'
import IconWrapper from './IconWrapper'

const SmallArrowIcon = ({ width, height, display, transform }) => (
  <IconWrapper
    width={width}
    height="100%"
    display={display}
    transform={transform}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 37 37"
      fill="none">
      <path
        d="M28.6475 18.093L7.53875 18.093"
        stroke="white"
        strokeWidth="1.80932"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0928 28.6475L7.53842 18.0931L18.0928 7.53875"
        stroke="white"
        strokeWidth="1.80932"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </IconWrapper>
)

SmallArrowIcon.propTypes = {
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  display: PropTypes.string,
  transform: PropTypes.string,
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
  ]),
}

SmallArrowIcon.defaultProps = {
  width: '37px',
  height: '37px',
  display: 'inline-block',
  transform: 'rotate(0)',
}

export default SmallArrowIcon
