import { createGlobalStyle } from 'styled-components'
import { normalize } from 'polished'
import theme from './theme'
import { notoSansCyrillic } from './fonts'

const globalStyle = (fontFaceStr) => createGlobalStyle`
  ${fontFaceStr}
  ${notoSansCyrillic}
  ${normalize}

  html {
    font-size: 16px;
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    font-family: 'BrownStd-Regular', sans-serif;
    font-feature-settings: 'kern', 'liga', 'clig', 'calt';
    font-size: 1rem;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-weight: normal;
    overflow-x: hidden;
  }

  p {
    margin-top: 0;
  }

  a {
    color: currentColor;
    text-decoration: none;
  }

  h2,
  h3,
  h4,
  h5 {
    color: ${theme.colors.sagedark};
  }

  button,
  select {
    cursor: pointer;
  }

  .evidon-banner-message a {
    text-decoration: underline;
  }

  .audience-variation {
    display: none;
  }

  .audience-default {
    display: inherit;
  }

  /* stylelint-disable */
  .whatsapp-widget {
    position: fixed;
    z-index: 1000000;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    bottom: 20px;
    right: 20px;
    display: inline-block;
    line-height: 1;
    display: none;
  }

  @media only screen and (max-width: 768px) {
    .whatsapp-widget {
      display: block;
    }
  }
  /* stylelint-enable */
`

export default globalStyle
