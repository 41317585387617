import React from 'react'
import PropTypes from 'prop-types'
import theme from 'styles/theme'
import IconWrapper from './IconWrapper'

const ArrowShortIcon = ({ color, width, size, display, transform }) => (
  <IconWrapper
    width={width}
    height="100%"
    display={display}
    transform={transform}>
    <svg width="100%" height="100%" viewBox="0 0 24 24">
      <path
        d="M15 6L9 12L15 18"
        stroke={`#${color}`}
        strokeWidth={size}
        fill="none"
      />
    </svg>
  </IconWrapper>
)

ArrowShortIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  display: PropTypes.string,
  transform: PropTypes.string,
  size: PropTypes.string,
}

ArrowShortIcon.defaultProps = {
  color: theme.colors.coaldark,
  width: [],
  display: 'inline-block',
  transform: 'rotate(0)',
  size: '',
}

export default ArrowShortIcon
