const audiences = {
  china: '14346020447',
  new: '14344270116',
  returning: '14365790507',
  bicester: '14373910161',
  member: '14348170213',
  DemoUser: '16399770230',
  DemoUser2: '16520010462',
}

export const inferredAudience = audiences.DemoUser2

export const fixAudiences = (audienceGroup) => {
  const cleanAudiences = []
  let isDefault = false

  if (typeof audienceGroup === 'string') {
    return audienceGroup
  }

  // if multiple audiences per item
  if (
    audienceGroup != null &&
    audienceGroup.length > 0 &&
    typeof audienceGroup[Symbol.iterator] === 'function'
  ) {
    // eslint-disable-next-line func-names
    audienceGroup.forEach(function(item) {
      cleanAudiences.push(`audience-variation-${item.content}`)
      if (item.content === inferredAudience) {
        isDefault = true
      }
    })
    // single audience per item
  } else if (
    audienceGroup != null &&
    audienceGroup.length > 0 &&
    typeof audienceGroup === 'object'
  ) {
    cleanAudiences.push(`audience-variation-${audienceGroup.content}`)
    if (audienceGroup.content === inferredAudience) {
      isDefault = true
    }
  }

  if (isDefault) {
    cleanAudiences.push('audience-default')
  } else if (audienceGroup != null && audienceGroup.length > 0) {
    cleanAudiences.push('audience-variation')
  }

  return cleanAudiences.join(' ')
}

export default audiences
