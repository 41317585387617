exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-brand-details-page-template-t-06-js": () => import("./../../../src/templates/BrandDetailsPageTemplateT06.js" /* webpackChunkName: "component---src-templates-brand-details-page-template-t-06-js" */),
  "component---src-templates-brand-directory-page-t-05-js": () => import("./../../../src/templates/BrandDirectoryPageT05.js" /* webpackChunkName: "component---src-templates-brand-directory-page-t-05-js" */),
  "component---src-templates-campaign-offers-page-template-t-18-js": () => import("./../../../src/templates/CampaignOffersPageTemplateT18.js" /* webpackChunkName: "component---src-templates-campaign-offers-page-template-t-18-js" */),
  "component---src-templates-dine-details-page-template-t-07-js": () => import("./../../../src/templates/DineDetailsPageTemplateT07.js" /* webpackChunkName: "component---src-templates-dine-details-page-template-t-07-js" */),
  "component---src-templates-dine-landing-page-template-t-02-js": () => import("./../../../src/templates/DineLandingPageTemplateT02.js" /* webpackChunkName: "component---src-templates-dine-landing-page-template-t-02-js" */),
  "component---src-templates-editorial-page-template-t-08-js": () => import("./../../../src/templates/EditorialPageTemplateT08.js" /* webpackChunkName: "component---src-templates-editorial-page-template-t-08-js" */),
  "component---src-templates-filtered-product-guide-page-template-19-js": () => import("./../../../src/templates/FilteredProductGuidePageTemplate19.js" /* webpackChunkName: "component---src-templates-filtered-product-guide-page-template-19-js" */),
  "component---src-templates-generic-page-template-t-13-js": () => import("./../../../src/templates/GenericPageTemplateT13.js" /* webpackChunkName: "component---src-templates-generic-page-template-t-13-js" */),
  "component---src-templates-gift-guides-page-template-t-16-js": () => import("./../../../src/templates/GiftGuidesPageTemplateT16.js" /* webpackChunkName: "component---src-templates-gift-guides-page-template-t-16-js" */),
  "component---src-templates-group-booking-page-t-10-js": () => import("./../../../src/templates/GroupBookingPageT10.js" /* webpackChunkName: "component---src-templates-group-booking-page-t-10-js" */),
  "component---src-templates-home-page-template-t-01-js": () => import("./../../../src/templates/HomePageTemplateT01.js" /* webpackChunkName: "component---src-templates-home-page-template-t-01-js" */),
  "component---src-templates-lifestyle-page-template-t-17-js": () => import("./../../../src/templates/LifestylePageTemplateT17.js" /* webpackChunkName: "component---src-templates-lifestyle-page-template-t-17-js" */),
  "component---src-templates-memorable-days-page-template-t-14-js": () => import("./../../../src/templates/MemorableDaysPageTemplateT14.js" /* webpackChunkName: "component---src-templates-memorable-days-page-template-t-14-js" */),
  "component---src-templates-section-detail-page-template-t-03-js": () => import("./../../../src/templates/SectionDetailPageTemplateT03.js" /* webpackChunkName: "component---src-templates-section-detail-page-template-t-03-js" */),
  "component---src-templates-section-landing-page-template-t-02-js": () => import("./../../../src/templates/SectionLandingPageTemplateT02.js" /* webpackChunkName: "component---src-templates-section-landing-page-template-t-02-js" */),
  "component---src-templates-services-booking-contact-page-js": () => import("./../../../src/templates/ServicesBookingContactPage.js" /* webpackChunkName: "component---src-templates-services-booking-contact-page-js" */),
  "component---src-templates-services-checkout-page-js": () => import("./../../../src/templates/ServicesCheckoutPage.js" /* webpackChunkName: "component---src-templates-services-checkout-page-js" */),
  "component---src-templates-shopping-express-booking-page-js": () => import("./../../../src/templates/ShoppingExpressBookingPage.js" /* webpackChunkName: "component---src-templates-shopping-express-booking-page-js" */),
  "component---src-templates-shopping-packages-landing-page-js": () => import("./../../../src/templates/ShoppingPackagesLandingPage.js" /* webpackChunkName: "component---src-templates-shopping-packages-landing-page-js" */),
  "component---src-templates-travel-registration-page-t-10-js": () => import("./../../../src/templates/TravelRegistrationPageT10.js" /* webpackChunkName: "component---src-templates-travel-registration-page-t-10-js" */),
  "component---src-templates-village-opening-hours-page-js": () => import("./../../../src/templates/VillageOpeningHoursPage.js" /* webpackChunkName: "component---src-templates-village-opening-hours-page-js" */),
  "component---src-templates-whats-on-page-template-t-09-js": () => import("./../../../src/templates/WhatsOnPageTemplateT09.js" /* webpackChunkName: "component---src-templates-whats-on-page-template-t-09-js" */)
}

