import React from 'react'
import PropTypes from 'prop-types'
import theme from 'styles/theme'
import IconWrapper from './IconWrapper'

const PinterestIcon = ({ color, width, height, display }) => (
  <IconWrapper width={width} height={height} display={display}>
    <svg width={width} height={height} viewBox="0 0 40 40">
      <g transform="translate(2 2)" fill="none" fillRule="evenodd">
        <circle
          stroke={theme.colors.sagelight}
          strokeLinecap="square"
          cx="18"
          cy="18"
          r="18"
        />
        <path
          d="M18 9a9 9 0 0 0-3.28 17.38c-.079-.711-.15-1.804.032-2.581.163-.702 1.055-4.474 1.055-4.474s-.27-.539-.27-1.336c0-1.251.726-2.186 1.629-2.186.768 0 1.139.577 1.139 1.268 0 .773-.492 1.927-.745 2.997-.212.896.448 1.627 1.332 1.627 1.6 0 2.83-1.687 2.83-4.122 0-2.155-1.55-3.662-3.76-3.662-2.56 0-4.064 1.921-4.064 3.906 0 .774.298 1.603.67 2.054.073.09.084.167.062.258-.068.285-.22.896-.25 1.021-.04.164-.13.2-.3.12-1.125-.523-1.828-2.166-1.828-3.487 0-2.838 2.063-5.446 5.947-5.446 3.122 0 5.549 2.225 5.549 5.198 0 3.102-1.956 5.598-4.67 5.598-.913 0-1.77-.474-2.063-1.033 0 0-.452 1.718-.561 2.14-.203.78-.752 1.76-1.12 2.358A9 9 0 1 0 18 9"
          fill={color}
        />
      </g>
    </svg>
  </IconWrapper>
)

PinterestIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  display: PropTypes.string,
}

PinterestIcon.defaultProps = {
  color: theme.colors.coaldark,
  width: '40px',
  height: '40px',
  display: 'inline-block',
}

export default PinterestIcon
