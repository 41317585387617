import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import { navigate } from '@reach/router'
import params from 'utils/params'
import Onboarding from './Onboarding'
import Trigger from './Trigger'

const MemorableDays = ({ location, onboarding }) => {
  const modalId = 'memorable-days'
  const { triggerIcon } = onboarding
  const locationParams = params.decode(location.search)
  const open = () => {
    // @ts-ignore
    window.modalIsOpened = true
    navigate(params.encode({ modal: modalId }, location.search))
  }
  const close = () => {
    // @ts-ignore
    window.modalIsOpened = false
    navigate(location.pathname)
  }

  const show =
    locationParams && locationParams.modal && locationParams.modal === modalId

  useEffect(() => {
    // @ts-ignore
    window.modalIsOpened = show
    return () => {}
  }, [show])

  return (
    <>
      <Trigger logo={triggerIcon.code.code} open={open} />
      <CSSTransition classNames="fade" in={show} timeout={600} unmountOnExit>
        <Onboarding location={location} close={close} {...onboarding} />
      </CSSTransition>
    </>
  )
}

MemorableDays.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }),
  onboarding: PropTypes.instanceOf(Object),
}

MemorableDays.defaultProps = {
  location: {},
  onboarding: null,
}

export default MemorableDays
