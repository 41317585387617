import React from 'react'
import PropTypes from 'prop-types'
import theme from 'styles/theme'
import IconWrapper from './IconWrapper'

const InstagramIcon = ({ color, width, height, display }) => (
  <IconWrapper width={width} height={height} display={display}>
    <svg width={width} height={height} viewBox="0 0 40 40">
      <g transform="translate(2 2)" fill="none" fillRule="evenodd">
        <circle
          stroke={theme.colors.sagelight}
          strokeLinecap="square"
          cx="18"
          cy="18"
          r="18"
        />
        <path
          d="M24.512 21.233c.038-.844.047-1.097.047-3.233s-.009-2.39-.047-3.233c-.036-.78-.166-1.203-.276-1.485a2.479 2.479 0 0 0-.598-.92 2.479 2.479 0 0 0-.92-.598c-.282-.11-.705-.24-1.485-.276-.844-.038-1.097-.047-3.233-.047s-2.39.009-3.233.047c-.78.036-1.203.166-1.485.276-.374.145-.64.318-.92.598a2.48 2.48 0 0 0-.598.92c-.11.282-.24.705-.276 1.485-.038.844-.047 1.097-.047 3.233s.009 2.39.047 3.233c.036.78.166 1.203.276 1.485.145.374.318.64.598.92.28.28.546.453.92.598.282.11.705.24 1.485.276.844.038 1.097.047 3.233.047s2.39-.009 3.233-.047c.78-.036 1.203-.166 1.485-.276.374-.145.64-.318.92-.598.28-.28.453-.546.598-.92.11-.282.24-.705.276-1.485zm1.44-6.531c.039.853.048 1.125.048 3.298s-.01 2.445-.048 3.298c-.039.852-.174 1.433-.372 1.942a3.922 3.922 0 0 1-.923 1.417c-.444.445-.89.719-1.417.923-.509.198-1.09.333-1.942.372-.853.039-1.125.048-3.298.048s-2.445-.01-3.298-.048c-.852-.039-1.433-.174-1.942-.372a3.921 3.921 0 0 1-1.417-.923 3.922 3.922 0 0 1-.923-1.417c-.198-.509-.333-1.09-.372-1.942C10.01 20.445 10 20.173 10 18s.01-2.445.048-3.298c.039-.852.174-1.433.372-1.942.204-.526.478-.973.923-1.417.444-.445.89-.719 1.417-.923.509-.198 1.09-.333 1.942-.372C15.555 10.01 15.827 10 18 10s2.445.01 3.298.048c.852.039 1.433.174 1.942.372.526.204.973.478 1.417.923.445.444.719.89.923 1.417.198.509.333 1.09.372 1.942zm-7.95 6.072c1.484 0 2.687-1.182 2.687-2.64 0-1.46-1.203-2.642-2.686-2.642-1.484 0-2.687 1.182-2.687 2.641s1.203 2.641 2.687 2.641zm0-6.71c2.287 0 4.14 1.822 4.14 4.07 0 2.246-1.853 4.068-4.14 4.068-2.285 0-4.139-1.822-4.139-4.069s1.854-4.069 4.14-4.069zm4.278-1.356c.533 0 .966.425.966.95 0 .523-.433.949-.966.949a.958.958 0 0 1-.966-.95c0-.524.432-.95.966-.95z"
          fill={color}
        />
      </g>
    </svg>
  </IconWrapper>
)

InstagramIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  display: PropTypes.string,
}

InstagramIcon.defaultProps = {
  color: theme.colors.coaldark,
  width: '40px',
  height: '40px',
  display: 'inline-block',
}

export default InstagramIcon
