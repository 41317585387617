import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Content from './Content'
import Tabs from './Tabs'
import { Body, Curve, Headline } from './DinePromo.style'

const DinePromo = ({ headline, tabs: propTabs, villageSlug }) => {
  const [active, setActive] = useState(0)
  const requiredFields = ['bodyCopy', 'image', 'tabTitle']
  const tabs = propTabs.filter((obj) =>
    requiredFields.every((key) => !!obj[key])
  )
  const tab = tabs && tabs[active]

  return (
    <>
      <Curve>
        <circle cx="187.5" cy="376.0875" r="376.0875" />
      </Curve>
      <Body>
        <Headline>{headline}</Headline>
        {tabs && tabs.length > 1 && (
          <Tabs villageSlug={villageSlug} {...{ active, setActive, tabs }} />
        )}
        {tab && <Content {...tab} key={`content${active.toString()}`} />}
      </Body>
      <Curve>
        <circle cx="187.5" cy="-326.0875" r="376.0875" />
      </Curve>
    </>
  )
}

DinePromo.propTypes = {
  headline: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      bodyCopy: PropTypes.string,
      ctaCopy: PropTypes.string,
      ctaUrl: PropTypes.string,
      image: PropTypes.shape({
        altText: PropTypes.string,
        landscape: PropTypes.shape({
          gatsbyImageData: PropTypes.shape({
            src: PropTypes.string,
          }),
        }),
        portrait: PropTypes.shape({
          gatsbyImageData: PropTypes.shape({
            src: PropTypes.string,
          }),
        }),
      }),
      eyebrow: PropTypes.string,
      tabTitle: PropTypes.string,
    })
  ),
  villageSlug: PropTypes.string,
}

DinePromo.defaultProps = {
  headline: '',
  tabs: [],
  villageSlug: '',
}

export default DinePromo
