import React from 'react'
import PropTypes from 'prop-types'
import { Share } from 'components/MemorableDays'
import { Body, Header, Wrapper } from './ShareModal.style'

const ShareModal = ({ closeUrl, ...shareProps }) => (
  <Wrapper>
    <Header closeUrl={closeUrl} />
    <Body>
      <Share {...shareProps} colorScheme="dark" showPinterest={false} />
    </Body>
  </Wrapper>
)

ShareModal.propTypes = {
  bodyCopy: PropTypes.string,
  closeUrl: PropTypes.string,
  ctaLabel: PropTypes.string,
  headline: PropTypes.string,
}

ShareModal.defaultProps = {
  bodyCopy: '',
  closeUrl: '',
  ctaLabel: '',
  headline: '',
}

export default ShareModal
