import React from 'react'
import PropTypes from 'prop-types'
import theme from 'styles/theme'
import { FacebookIcon, PinterestIcon, TwitterIcon } from 'components/Icons'
import Link from 'components/Link'
import { SocialSharingWrapper, SocialSharingItem } from './SocialSharing.style'

const fallbackShareUrl = ({ href, pathname }) => {
  return `${href.split(pathname)[0]}${pathname}`
}

const SocialSharing = ({ urlToShare, showPinterest = true }) => {
  const shareLink = encodeURIComponent(
    urlToShare || typeof window !== 'undefined'
      ? fallbackShareUrl(window.location)
      : null
  )
  const facebookItem = {
    name: 'Facebook',
    src: <FacebookIcon color={theme.colors.sagemedium} />,
    link: `https://www.facebook.com/sharer/sharer.php?u=${shareLink}`,
  }
  const pinterestItem = {
    name: 'Pinterest',
    src: <PinterestIcon color={theme.colors.sagemedium} />,
    link: `https://pinterest.com/pin/create/button/?url=${shareLink}&media=&description=`,
  }
  const twitterItem = {
    name: 'Twitter',
    src: <TwitterIcon color={theme.colors.sagemedium} />,
    link: `https://twitter.com/intent/tweet?text=${shareLink}`,
  }
  const socialItems = showPinterest
    ? [facebookItem, pinterestItem, twitterItem]
    : [facebookItem, twitterItem]

  return (
    <SocialSharingWrapper as="ul">
      {socialItems.map(({ name, link, src }) => (
        <SocialSharingItem as="li" key={name}>
          <Link title={`${name}`} to={link} external>
            {src}
          </Link>
        </SocialSharingItem>
      ))}
    </SocialSharingWrapper>
  )
}

SocialSharing.propTypes = {
  urlToShare: PropTypes.string.isRequired,
  showPinterest: PropTypes.bool.isRequired,
}

export default SocialSharing
