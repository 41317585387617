import React from 'react'
import PropTypes from 'prop-types'
import { HeaderMenuWrapper, HeaderMenuList } from './HeaderMenu.style'
import HeaderMenuItem from './HeaderMenuItem'

const HeaderMenu = ({
  menuItems,
  shouldForceSandwichMenu,
  shouldShowSmallerFonts,
  showSkinny,
  showDark,
  navOpen,
  closeNav,
  villageSlug,
  villageName,
  valueRetailPage,
  locale,
  forceWhiteLabels,
  villages,
  displayVillageSelector,
  villageSelectorOpen,
  villageHandler,
  closeVillageSelector,
  pageLevel,
}) => {
  // Preparing HeaderMenuWrapper Props
  const headerMenuWrapperProps = {
    width: ['100%', null, null],
    height: '100%',
    textAlign: ['center'],
    flexDirection: ['column', null, 'row'],
    justifyContent: ['center', null, 'flex-start'],
    display: ['block', null, 'flex'],
    position: ['fixed', null, null],
    top: ['0', null, null],
    left: ['0', null, null],
  }
  if (!shouldForceSandwichMenu) {
    // If not a sandwich menu on desktop
    headerMenuWrapperProps.position[2] = 'relative'
    headerMenuWrapperProps.width[2] = 'auto'
  }

  // Preparing HeaderMenuList Props
  const headerMenuListProps = {
    as: 'ul',
    display: ['flex', null, null],
    flexDirection: ['column', null, null],
    flex: ['1 1 auto'],
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: [
      shouldShowSmallerFonts
        ? `${80 * menuItems.length}px`
        : `${100 * menuItems.length}px`,
      null,
      null,
    ],
    pt: ['160px', null, null],
    pb: ['160px', null, null],
    height: ['100vh', 'calc(100vh - 40px)', null],
    mb: ['0', '20px', null],
    mt: ['0', '20px', null],
  }
  if (!shouldForceSandwichMenu) {
    // If not a sandwich menu on desktop
    headerMenuListProps.flexDirection[2] = 'row'
    headerMenuListProps.minHeight[2] = 'auto'
    headerMenuListProps.height[2] = 'auto'
    headerMenuListProps.pt[2] = showSkinny ? '10px' : '25px'
    headerMenuListProps.pb[2] = '0'
    headerMenuListProps.mt[2] = '0'
    headerMenuListProps.mb[2] = '0'
  }

  return (
    <HeaderMenuWrapper {...headerMenuWrapperProps}>
      <HeaderMenuList {...headerMenuListProps}>
        {menuItems &&
          menuItems.map((i, index) => {
            const key = `menuLink_${index}`
            return (
              <HeaderMenuItem
                key={key}
                {...{
                  item: { ...i, index },
                  forceWhiteLabels,
                  showSkinny,
                  showDark,
                  navOpen,
                  shouldForceSandwichMenu,
                  shouldShowSmallerFonts,
                  closeNav,
                  villageSlug,
                  villageName,
                  valueRetailPage,
                  locale,
                  villages,
                  displayVillageSelector,
                  villageSelectorOpen,
                  villageHandler,
                  closeVillageSelector,
                  pageLevel,
                }}
              />
            )
          })}
      </HeaderMenuList>
    </HeaderMenuWrapper>
  )
}

HeaderMenu.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
    .isRequired,
  navOpen: PropTypes.bool.isRequired,
  closeNav: PropTypes.func.isRequired,
  shouldForceSandwichMenu: PropTypes.bool.isRequired,
  shouldShowSmallerFonts: PropTypes.bool.isRequired,
  showSkinny: PropTypes.bool.isRequired,
  showDark: PropTypes.bool.isRequired,
  villageSlug: PropTypes.string,
  villageName: PropTypes.string,
  valueRetailPage: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  forceWhiteLabels: PropTypes.bool,
  villages: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
  }),
  displayVillageSelector: PropTypes.bool.isRequired,
  villageSelectorOpen: PropTypes.bool.isRequired,
  villageHandler: PropTypes.func.isRequired,
  closeVillageSelector: PropTypes.func.isRequired,
  pageLevel: PropTypes.string,
}

HeaderMenu.defaultProps = {
  forceWhiteLabels: false,
  villageSlug: '',
  villageName: '',
  valueRetailPage: false,
  villages: null,
  pageLevel: '',
}

export default HeaderMenu
