import { rem } from 'polished'
import styled from 'styled-components'
import theme from 'styles/theme'
import { Body16, Eyebrow, Title60 } from 'components/Typography'

export const Wrapper = styled.div`
  color: ${({ colorScheme }) =>
    colorScheme && colorScheme === 'dark'
      ? theme.colors.white
      : theme.colors.sagedark};
  text-align: center;
  padding: 30px 20px 60px;

  ${theme.mediaQueries.medium} {
    padding: 50px 40px 100px;
  }

  path {
    fill: currentColor;
  }
`

export const Body = styled(Body16)`
  font-size: ${rem(16)};
  margin: 1em 0;
`

export const Headline = styled(Title60)`
  color: inherit;
  font-size: ${rem(42)};
  letter-spacing: -0.05em;
  margin: 0 auto 0.5em;
  max-width: 500px;

  ${theme.mediaQueries.medium} {
    font-size: ${rem(60)};
  }
`

export const Prompt = styled(Eyebrow)`
  margin: 40px 0 12px;
`
