import React from 'react'
import PropTypes from 'prop-types'
import Link from 'components/Link'
import { Wrapper, Copy, Divider, Icon } from './FooterIcp.styles'
import icon from './icp-icon.png'

const FooterIcp = ({ pathOrigin }) => {
  let icpDetails = '沪ICP备15011106号-21'

  if (pathOrigin.indexOf('value-retail.cn') > -1) {
    icpDetails = '沪ICP备15011106号-7'
  } else if (pathOrigin.indexOf('tbvsc.cn') > -1)
    icpDetails = '沪ICP备15011106号-17'

  return (
    <Wrapper>
      <Link to="http://beian.miit.gov.cn/" external>
        <Copy>{icpDetails}</Copy>
      </Link>
      <Divider>&nbsp;|&nbsp;</Divider>
      <Icon src={icon} alt="ICP" height="14" width="14" />
      <Link
        to="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502007142"
        external>
        <Copy>沪公网安备 31011502007142号</Copy>
      </Link>
    </Wrapper>
  )
}

FooterIcp.propTypes = {
  pathOrigin: PropTypes.string,
}

FooterIcp.defaultProps = {
  pathOrigin: '',
}

export default FooterIcp
