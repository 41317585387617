import React from 'react'
import PropTypes from 'prop-types'
import GA4 from 'utils/GA4'
import {
  HeaderLoginLink,
  HeaderLoginText,
  HeaderLoginLinkItem,
} from './HeaderLogin.style'

const HeaderLoginItem = ({
  label,
  href,
  showDark,
  navOpen,
  shouldForceSandwichMenu,
  logInVar,
  villageName,
}) => {
  // Preparing LanguageLink Props
  const languageLinkProps = {
    to: href,
    color: [showDark || navOpen ? 'slate' : 'white', null, null],
    // z: true, /* produces error Bolean invalid vaue @Fernando - is this needed? */
  }

  if (!shouldForceSandwichMenu) {
    languageLinkProps.color[2] = 'slate'
  }

  // Preparing LanguageText Props
  const languageTextProps = {
    fontSize: {
      default: '12px',
    },
    textTransform: {
      default: 'uppercase',
    },
    fontFamily: {
      default: 'BrownStd-Regular, sans-serif',
    },
    letterSpacing: {
      default: '1px',
    },
    lineHeight: {
      default: '1',
      sm: '1',
      md: '1',
      lg: '1',
      xl: '1',
    },
    fontWeight: {
      default: 'normal',
    },
  }

  // Preparing LanguageItem Props
  const languageItemProps = {
    as: 'li',
    p: ['15px 0'],
    textAlign: 'center',
    display: 'block',
  }
  if (!shouldForceSandwichMenu) {
    // @ts-ignore
    languageItemProps.display = label ? ['block', 'block', 'block'] : 'block'
  }

  const content = (
    <HeaderLoginText {...languageTextProps}>{label}</HeaderLoginText>
  )

  return (
    <HeaderLoginLinkItem
      display={label ? ['block', 'block', 'block'] : 'block'}
      {...languageItemProps}>
      <HeaderLoginLink
        {...languageLinkProps}
        onClick={() => {
          GA4(
            logInVar === 'Sign_In'
              ? 'header_navigation_login'
              : 'header_navigation_registration',
            { village_name: villageName }
          )
        }}>
        {content}
      </HeaderLoginLink>
    </HeaderLoginLinkItem>
  )
}

HeaderLoginItem.propTypes = {
  showSkinny: PropTypes.bool,
  showDark: PropTypes.bool.isRequired,
  navOpen: PropTypes.bool.isRequired,
  shouldForceSandwichMenu: PropTypes.bool.isRequired,
  closeLanguageSelector: PropTypes.func.isRequired,
  closeNav: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  languageList: PropTypes.string,
  translatedSlugs: PropTypes.objectOf(PropTypes.string),
  label: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  logInVar: PropTypes.string,
  villageName: PropTypes.string,
}

HeaderLoginItem.defaultProps = {
  translatedSlugs: {},
  showSkinny: false,
  languageList: [],
  logInVar: '',
  villageName: '',
}

export default HeaderLoginItem
