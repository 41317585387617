import { rem } from 'polished'
import colors from './colors'

const sangbleuKindgdom = {
  fontWeight: 'normal',
  fontFamily: 'SangbleuKindgdom-Light, serif',
}

const brownStd = {
  fontFamily: 'BrownStd-Regular, sans-serif',
}

const title100 = {
  fontSize: {
    default: rem(100),
  },
  fontFamily: 'SangbleuKindgdom-Light-Italic, serif',
  letterSpacing: '-0.5px',
  lineHeight: ['1.25'],
}

const title70 = {
  fontSize: {
    default: rem(42),
    lg: rem(70),
  },
  ...sangbleuKindgdom,
  letterSpacing: '-3.5px',
  lineHeight: ['1.11'],
}

const title60 = {
  fontSize: {
    default: rem(32),
    lg: rem(60),
  },
  ...sangbleuKindgdom,
  letterSpacing: '-3px',
  lineHeight: ['1.13'],
}

const title42 = {
  fontSize: {
    default: rem(26),
    lg: rem(42),
  },
  ...sangbleuKindgdom,
  letterSpacing: '-2px',
  lineHeight: ['1.14'],
}

const title32 = {
  fontSize: {
    default: rem(26),
    lg: rem(32),
  },
  ...sangbleuKindgdom,
  letterSpacing: '-1px',
  lineHeight: ['1.25'],
}

const title26 = {
  fontSize: {
    default: rem(26),
  },
  ...sangbleuKindgdom,
  letterSpacing: '-1.2px',
  lineHeight: ['1.4'],
}

const title20 = {
  fontSize: {
    default: rem(20),
  },
  ...sangbleuKindgdom,
  letterSpacing: '-1px',
  lineHeight: ['1.25'],
}

const title16 = {
  fontSize: {
    default: rem(16),
  },
  ...sangbleuKindgdom,
  letterSpacing: '-1px',
  lineHeight: ['1.25'],
}

const title20Italics = {
  ...title20,
  fontFamily: 'SangbleuKindgdom-Light-Italic, serif',
  letterSpacing: '-0.5px',
  lineHeight: ['1.25'],
}

const body20 = {
  fontSize: {
    default: rem(18),
    lg: rem(20),
  },
  ...brownStd,
  lineHeight: ['1.6'],
}

const body18 = {
  fontSize: {
    default: rem(16),
    lg: rem(18),
  },
  ...brownStd,
  lineHeight: ['1.6'],
}

const body16 = {
  fontSize: {
    default: rem(14),
    lg: rem(16),
  },
  ...brownStd,
  lineHeight: ['1.5'],
}

const body14 = {
  fontSize: {
    default: rem(14),
  },
  ...brownStd,
  lineHeight: ['1.42'],
}

const body12 = {
  fontSize: {
    default: rem(12),
  },
  ...brownStd,
  lineHeight: ['1.5'],
}

const body12Uppercase = {
  ...body12,
  textTransform: 'uppercase',
  letterSpacing: '1px',
}
const body12UppercaseCondensed = {
  ...body12,
  textTransform: 'uppercase',
  letterSpacing: '-0.2px',
  lineHeight: ['1.2'],
}

const body10Uppercase = {
  fontSize: {
    default: rem(10),
  },
  textTransform: 'uppercase',
  ...brownStd,
  letterSpacing: '1.5px',
  lineHeight: ['1.2'],
}

const body10UppercaseCondensed = {
  fontSize: {
    default: rem(10),
  },
  textTransform: 'uppercase',
  ...brownStd,
  letterSpacing: '-0.2px',
  lineHeight: ['1.2'],
}

const body8Uppercase = {
  fontSize: {
    default: rem(8),
  },
  textTransform: 'uppercase',
  ...brownStd,
  letterSpacing: '1.2px',
  lineHeight: ['1.5'],
}

const label = {
  ...body8Uppercase,
  padding: '1px 4px',
  bg: colors.sagelight,
  display: 'inline-block',
}

export default {
  title70,
  title60,
  title42,
  title32,
  title26,
  title20,
  title20Italics,
  title16,
  body20,
  body18,
  body16,
  body14,
  body12,
  body12Uppercase,
  body12UppercaseCondensed,
  body10Uppercase,
  body10UppercaseCondensed,
  body8Uppercase,
  title100,
  label,
}
