import React from 'react'
import styled, { css } from 'styled-components'
import {
  color,
  fontFamily,
  fontWeight,
  letterSpacing,
  lineHeight,
  fontSize,
} from 'styled-system'
import { Box } from 'rebass'
import Link from 'components/Link'
import { appearingAnimation } from 'styles/animation'
import { textTransform as textTransformStyle } from 'styles/sharedStyle'
import theme from 'styles/theme'

const diamondStyle = css`
  @media only screen and (min-width: ${theme.breakpoints[1]}) {
    display: block;
    position: relative;
    overflow: visible;
    ::after {
      content: '♦︎';
      display: block;
      font-size: 5px;
      transition: opacity 0.3s ease-in-out;
      margin-top: 10px;
      opacity: 1;
    }
    ::before {
      content: '♦︎';
      display: block;
      font-size: 5px;
      margin-top: 10px;
      visibility: hidden;
    }
  }
`

export const HeaderMenuLink = styled(
  ({ allowDiamond, textTransform, ...rest }) => <Link {...rest} />
)`
  border: 0;
  ${color}

  ${(props) => (props.allowDiamond ? diamondStyle : '')}

  @media only screen and (min-width: ${theme.breakpoints[1]}) {
    white-space: nowrap;
    ::after {
      opacity: 0;
    }

    :hover::after{
      opacity: 1;
    }
  }
`

export const HeaderMenuLinkActive = styled(
  ({ allowDiamond, textTransform, ...rest }) => <Link {...rest} />
)`
  border: 0;
  ${color}

  ${(props) => (props.allowDiamond ? diamondStyle : '')}
`

export const HeaderMenuItem = styled(Box)`
  opacity: 0;
  top: 0px;
  animation: ${appearingAnimation('50px')} 0.3s ease-in-out;
  animation-direction: normal;
  animation-delay: ${(props) => props.delay};
  animation-duration: ${(props) => props.duration};
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`

export const HeaderMenuText = styled.span`
  ${textTransformStyle}
  ${fontFamily}
  ${fontWeight}
  ${lineHeight}
  ${fontSize}
  ${color}
  ${letterSpacing}
`

export default {
  HeaderMenuLink,
  HeaderMenuLinkActive,
  HeaderMenuText,
  HeaderMenuItem,
}
