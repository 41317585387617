/*
 * Converts window.location.search string into an object
 *
 * @param search String
 * return Object
 */
const decode = (search) => {
  const params = {}
  if (search && search.length) {
    Array.prototype.forEach.call(
      search.replace(/^\?/, '').split('&'),
      (str) => {
        const [key, value] = str.split('=') || []
        params[key] = value
      }
    )
  }
  return params
}

/*
 * Accepts an object and a window.location.search string and returns
 * a concatentated search string
 *
 * @param params Object
 * @param search? String
 * return String
 */
const encode = (params, search) => {
  const concatParams = Object.assign(decode(search), params)
  return `?${Object.keys(concatParams)
    .map((key) => `${key}=${concatParams[key]}`)
    .join('&')}`.replace(/^\?$/, '')
}

export default { decode, encode }
