import React, { useState } from 'react'
import PropTypes from 'prop-types'
import GA4 from 'utils/GA4'
import { Body12 } from 'components/Typography'
import { Tab, TabButton, TabSelected, Wrapper } from './Tabs.style'

const Tabs = ({ active, setActive, tabs, villageSlug }) => {
  const [hovered, setHovered] = useState(active)
  const hasActiveTab = true
  const handleMouseEnter = (item) => item !== hovered && setHovered(item)
  const handleMouseLeave = (item) => item !== active && setHovered(active)
  const itemWidth = 1 / tabs.length

  return (
    <Wrapper>
      {tabs.map(({ tabTitle }, i) => (
        <TabButton
          onMouseEnter={() => handleMouseEnter(i)}
          onMouseLeave={() => handleMouseLeave(i)}
          onClick={() => setActive(i)}
          width={itemWidth}
          // eslint-disable-next-line react/no-array-index-key
          key={i.toString()}>
          <Tab
            active={i === active}
            onClick={() =>
              GA4('inpage_tabs_click', {
                headline: tabTitle,
                village_name: villageSlug,
              })
            }>
            <Body12 as="span" uppercase>
              {tabTitle}
            </Body12>
          </Tab>
        </TabButton>
      ))}
      <TabSelected
        itemWidth={itemWidth}
        hoveredItem={hovered}
        display={['none', 'block', null]}
        hasActiveTab={hasActiveTab}
      />
    </Wrapper>
  )
}

Tabs.propTypes = {
  active: PropTypes.number,
  setActive: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabTitle: PropTypes.string,
    })
  ),
  villageSlug: PropTypes.string,
}

Tabs.defaultProps = {
  active: null,
  setActive: () => {},
  tabs: [],
  villageSlug: '',
}

export default Tabs
