import styled from 'styled-components'
import theme from 'styles/theme'
import { Body18 } from 'components/Typography'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ backgroundColor }) =>
    backgroundColor
      ? `#${backgroundColor}`
      : theme.colors.emergencyBannerBackground};
  width: 100vw;
  padding: ${theme.space[1]} ${theme.space[2]};
  opacity: ${({ transparent }) => (transparent ? 0 : 1)};

  transition: opacity 0.5s;
`

export const BannerText = styled(Body18)`
  color: ${({ textColor }) =>
    textColor ? `#${textColor}` : theme.colors.sagefeather};
  text-align: center;
  white-space: pre-wrap;
  font-family: 'SangbleuKindgdom-Light, serif';
`
