import React from 'react'
import PropTypes from 'prop-types'
import theme from 'styles/theme'
import IconWrapper from './IconWrapper'

const ChevronIcon = ({
  className,
  color,
  width,
  height,
  display,
  float,
  transform,
}) => (
  <IconWrapper
    className={className}
    width={width}
    height={height}
    display={display}
    float={float}
    transform={transform}>
    <svg width="100%" height="100%" viewBox="0 0 24 24">
      <path
        d="M8.4,12l8.3,8.3l-1.4,1.4L5.6,12l9.7-9.7l1.4,1.4L8.4,12z"
        fill={color}
      />
    </svg>
  </IconWrapper>
)

ChevronIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  display: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  transform: PropTypes.string,
  float: PropTypes.string,
}

ChevronIcon.defaultProps = {
  className: '',
  color: theme.colors.coaldark,
  width: [],
  height: [],
  display: 'inline-block',
  transform: 'rotate(0)',
  float: 'none',
}

export default ChevronIcon
