import React from 'react'
import PropTypes from 'prop-types'
import theme from 'styles/theme'
import { CtaLabel } from 'components/Typography'
import { ExternalLinkIcon } from 'components/Icons'
import {
  PrimaryWrapper,
  SecondaryWrapper,
  TertiaryWrapper,
  QuaternaryWrapper,
} from './Button.style'

const Button = ({
  as,
  children,
  level,
  type,
  external,
  ctaStyle,
  innerId,
  ...rest
}) => {
  const wrappers = {
    primary: PrimaryWrapper,
    secondary: SecondaryWrapper,
    tertiary: TertiaryWrapper,
    quaternary: QuaternaryWrapper,
  }
  const Wrapper = wrappers[level] || PrimaryWrapper
  const buttonType = as === 'button' || false

  return (
    <Wrapper
      ctaStyle={ctaStyle}
      as={as}
      {...(buttonType && { type })}
      {...rest}>
      <CtaLabel
        id={innerId}
        as="span"
        display="flex"
        alignItems="center"
        textTransform="uppercase">
        {children}
        {external && (
          <ExternalLinkIcon
            width="16px"
            color={theme.colors.sagemedium}
            verticalAlign="text-bottom"
            px="5px"
          />
        )}
      </CtaLabel>
    </Wrapper>
  )
}

Button.propTypes = {
  as: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
    PropTypes.string,
  ]),
  children: PropTypes.node.isRequired,
  level: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  external: PropTypes.bool,
  innerId: PropTypes.bool,
  id: PropTypes.string,
  ctaStyle: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
}

Button.defaultProps = {
  as: 'button',
  level: 'primary',
  type: 'button',
  onClick: () => {},
  external: false,
  id: undefined,
  innerId: undefined,
  ctaStyle: {},
}

export default Button
