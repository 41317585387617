import React from 'react'
import PropTypes from 'prop-types'
import theme from 'styles/theme'
import IconWrapper from './IconWrapper'

const YoutubeIcon = ({ color, width, height, display }) => (
  <IconWrapper width={width} height={height} display={display}>
    <svg width={width} height={height} viewBox="0 0 40 40">
      <g transform="translate(2 2)" fill="none" fillRule="evenodd">
        <circle
          stroke={theme.colors.sagelight}
          strokeLinecap="square"
          cx="18"
          cy="18"
          r="18"
        />
        <path
          d="M16.348 20.213v-4.794l4.323 2.405-4.323 2.389zm9.492-5.624s-.156-1.176-.636-1.694c-.609-.68-1.29-.683-1.603-.722C21.362 12 18.003 12 18.003 12h-.007s-3.358 0-5.597.173c-.313.04-.995.042-1.604.722-.479.518-.635 1.694-.635 1.694s-.16 1.38-.16 2.76v1.295c0 1.381.16 2.762.16 2.762s.156 1.175.635 1.693c.61.68 1.409.658 1.765.73C13.84 23.958 18 24 18 24s3.362-.005 5.601-.178c.313-.04.994-.043 1.603-.723.48-.518.636-1.693.636-1.693s.16-1.38.16-2.762V17.35c0-1.38-.16-2.761-.16-2.761z"
          fill={color}
        />
      </g>
    </svg>
  </IconWrapper>
)

YoutubeIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  display: PropTypes.string,
}

YoutubeIcon.defaultProps = {
  color: theme.colors.coaldark,
  width: '40px',
  height: '40px',
  display: 'inline-block',
}

export default YoutubeIcon
