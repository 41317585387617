import { rem } from 'polished'
import styled from 'styled-components'
import theme from 'styles/theme'

export const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 480px;
  width: 100%;

  ${theme.mediaQueries.smallOnly} {
    display: flex;
    justify-content: center;
    position: relative;
    transform: translateX(50vw);

    [dir='rtl'] & {
      transform: translateX(-50vw);
    }
  }

  ${theme.mediaQueries.medium} and (max-height: 960px) {
    min-width: 380px;
    width: 50vh;
  }

  ${theme.mediaQueries.medium} and (max-height: 860px) {
    width: calc(100vh - 430px);
  }

  ${theme.mediaQueries.medium} and (max-height: 800px) {
    width: 100%;
  }

  ${theme.mediaQueries.medium} and (min-height: 1000px) {
    padding-top: 30px;
  }
`

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${theme.mediaQueries.smallOnly} {
    flex: none;
    max-width: 480px;
    width: calc(100vw - 100px);
  }

  ${theme.mediaQueries.medium} and (max-height: 800px) {
    flex-wrap: nowrap;
  }
`

export const Item = styled.figure`
  flex: 0 0 45%;
  margin: 0;

  ${theme.mediaQueries.medium} and (max-height: 800px) {
    flex-basis: 23%;
  }

  ${({ dragging }) =>
    dragging &&
    `
    pointer-events: none;
  `}

  &:nth-child(n+3) {
    margin-top: 30px;

    ${theme.mediaQueries.medium} and (max-height: 800px) {
      margin-top: 0;
    }

    ${theme.mediaQueries.medium} and (min-height: 1000px) {
      margin-top: 60px;
    }
  }
`

export const Img = styled.div`
  border-radius: 100%;
  display: block;
  background: none 50% 50% / cover no-repeat ${theme.colors.coaldark};
  width: 100%;

  &::before {
    content: '';
    display: block;
    height: 0;
    padding-top: 100%;
    width: 100%;
  }
`

export const ItemText = styled.figcaption`
  ${theme.textStyles.body10Uppercase}
  font-size: ${rem(10)};
  margin: ${rem(10)} auto 0;
  max-width: 13em;

  ${theme.mediaQueries.medium} {
    margin-top: ${rem(12)};
  }
`
