import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
/* https://github.com/jonsuh/hamburgers/blob/master/dist/hamburgers.css */

const HamburgerButton = styled.button`
  background-color: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  margin: 0;
  overflow: visible;
  padding: 15px;
  text-transform: none;
  transition-duration: 0.15s;
  transition-property: opacity, filter;
  transition-timing-function: linear;

  &:hover {
    opacity: 0.7;
  }
`

const HamburgerBox = styled.span`
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;

  ${(props) => props.active && css``}
`

const HamburgerInner = styled.span`
  display: block;
  top: 50%;
  margin-top: -2px;

  &,
  &::before,
  &::after {
    ${(props) =>
      props.active &&
      css`
        background-color: red;
      `}
    background-color: #000;
    border-radius: 4px;
    height: 4px;
    position: absolute;
    transition-duration: 0.15s;
    transition-property: transform;
    transition-timing-function: ease;
    width: 40px;
  }

  &::before,
  &::after {
    content: '';
    display: block;
  }

  &::before {
    top: -10px;
  }

  &::after {
    bottom: -10px;
  }
`

const HamburgerIcon = ({ active }) => (
  <HamburgerButton active={active}>
    <HamburgerBox>
      <HamburgerInner active={active} />
    </HamburgerBox>
  </HamburgerButton>
)

HamburgerIcon.propTypes = {
  active: PropTypes.bool,
}

HamburgerIcon.defaultProps = {
  active: 0,
}

export default HamburgerIcon
