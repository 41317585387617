import React from 'react'
import PropTypes from 'prop-types'
import { Eyebrow } from 'components/Typography'
import FlexCenter from './FlexCenter'
import {
  Answer,
  AnswerText,
  Inner,
  Item,
  List,
  Question,
  Wrapper,
} from './OnboardingQList.style'

const OnboardingQList = ({
  answers,
  eyebrow,
  headline,
  pager,
  setAnswers,
  step,
}) => (
  <FlexCenter>
    <Wrapper>
      <Inner>
        {pager}
        {!!eyebrow && <Eyebrow>{eyebrow}</Eyebrow>}
        {!!headline && <Question>{headline}</Question>}
        {!!answers && answers.length > 0 && (
          <List>
            {answers.map(({ answer, slug }, i) => (
              <Item key={`${slug}${i.toString()}`}>
                <Answer
                  onClick={() => setAnswers(step - 1, slug, { advance: true })}>
                  <AnswerText>{answer}</AnswerText>
                </Answer>
              </Item>
            ))}
          </List>
        )}
      </Inner>
    </Wrapper>
  </FlexCenter>
)

OnboardingQList.propTypes = {
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      answer: PropTypes.string,
      slug: PropTypes.string,
    })
  ),
  eyebrow: PropTypes.string,
  headline: PropTypes.string,
  pager: PropTypes.node,
  setAnswers: PropTypes.func,
  step: PropTypes.number,
}

OnboardingQList.defaultProps = {
  answers: [],
  eyebrow: null,
  headline: null,
  pager: null,
  setAnswers: () => {},
  step: 0,
}

export default OnboardingQList
