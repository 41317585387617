// ignoring this rule as we have a global to not track any user info
/* eslint-disable no-underscore-dangle */

export default function(event, params) {
  if (typeof window === 'undefined') return

  // @ts-ignore
  if (window.__gtmDontTrack || !window.dataLayer) return

  let track = {}
  if (event) track = { ...track, event }
  if (params) track = { ...track, ...params }

  // @ts-ignore
  window.dataLayer.push(track)
}
