import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import SocialSharing from 'components/SocialSharing'
import { Body, Headline, Prompt, Wrapper } from './Share.style'

const Share = ({ bodyCopy, ctaLabel, headline, showPinterest, ...props }) => {
  const [showShare, setShowShare] = useState(false)

  useEffect(() => {
    if (window) {
      setShowShare(true)
    }
  }, [])

  return (
    <Wrapper {...props}>
      {!!headline && <Headline>{headline}</Headline>}
      {!!bodyCopy && <Body>{bodyCopy}</Body>}
      {!!ctaLabel && <Prompt>{ctaLabel}</Prompt>}
      {!!showShare && <SocialSharing showPinterest={showPinterest} />}
    </Wrapper>
  )
}

Share.propTypes = {
  bodyCopy: PropTypes.string,
  colorScheme: PropTypes.string,
  ctaLabel: PropTypes.string,
  headline: PropTypes.string,
  showPinterest: PropTypes.bool,
}

Share.defaultProps = {
  bodyCopy: '',
  colorScheme: '',
  ctaLabel: '',
  headline: '',
  showPinterest: true,
}

export default Share
