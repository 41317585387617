import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Img, Item, ItemText, Wrapper } from './OnboardingQOverview.style'

function getImage({ bgColor, image, thumbnail }) {
  const img =
    image && image.portrait && image.portrait.file && image.portrait.file.url
  const thumb =
    thumbnail &&
    thumbnail.portrait &&
    thumbnail.portrait.fixed &&
    thumbnail.portrait.fixed.src
  const src = thumb || img
  return src
    ? {
        style: {
          backgroundColor: `#${bgColor.trim().replace(/^#/, '')}`,
          backgroundImage: `url(${src})`,
        },
      }
    : {}
}

const OnboardingQOverview = ({ answers, dragging, itemClick }) => (
  <Wrapper>
    <Grid>
      {answers.map(({ answer, slug, ...rest }, i) => {
        const img = getImage(rest)
        return (
          <Item
            dragging={dragging}
            onClick={() => itemClick(slug)}
            key={`${slug}${i.toString()}`}>
            {!!img && <Img {...img} />}
            <ItemText>{answer}</ItemText>
          </Item>
        )
      })}
    </Grid>
  </Wrapper>
)

OnboardingQOverview.propTypes = {
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      answer: PropTypes.string,
      bgColor: PropTypes.string,
      image: PropTypes.shape({
        portrait: PropTypes.shape({
          file: PropTypes.shape({
            details: PropTypes.shape({
              image: PropTypes.shape({
                height: PropTypes.number,
                width: PropTypes.number,
              }),
            }),
            url: PropTypes.string,
          }),
        }),
      }),
      slug: PropTypes.string,
      thumbnail: PropTypes.shape({
        portrait: PropTypes.shape({
          fixed: PropTypes.shape({
            src: PropTypes.string,
          }),
        }),
      }),
    })
  ),
  dragging: PropTypes.bool,
  itemClick: PropTypes.func,
}

OnboardingQOverview.defaultProps = {
  answers: [],
  dragging: false,
  itemClick: () => {},
}

export default OnboardingQOverview
