import { rem } from 'polished'
import styled from 'styled-components'
import theme from 'styles/theme'
import { Title42 } from 'components/Typography'
import { InlineButton, tallDesktop } from './Onboarding.style'

export const Wrapper = styled.div`
  flex: none;
  padding: 36px 20px;
  width: 100%;
`

export const Inner = styled.div`
  background: ${theme.colors.white};
  margin: 0 auto;
  max-width: 566px;
  padding: 30px 30px 0;

  ${theme.mediaQueries.medium} {
    padding: 40px 40px 30px;
  }

  ${tallDesktop} {
    padding-bottom: 56px;
  }
`

export const Question = styled(Title42)`
  font-size: ${rem(32)};
`

export const List = styled.ul`
  display: block;
  list-style: none outside none;
  margin: 0;
  padding: 0;
`

export const Item = styled.li`
  border-top: 1px solid ${theme.colors.sagelight};
  display: block;

  &:first-child {
    border-top: 0 none;
  }
`

export const Answer = styled(InlineButton)`
  align-items: center;
  display: flex;
  font-size: ${rem(18)};
  height: 85px;
  justify-content: space-between;
  width: 100%;

  ${tallDesktop} {
    height: 118px;
  }

  &::after {
    border: 1px solid ${theme.colors.sagelight};
    border-radius: 100%;
    content: '';
    flex: none;
    display: block;
    height: 42px;
    width: 42px;
  }
`

export const AnswerText = styled.span`
  flex: 1 1 100%;
  text-align: initial;
`
