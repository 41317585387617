import React from 'react'
import PropTypes from 'prop-types'
import theme from 'styles/theme'
import IconWrapper from './IconWrapper'

const GridSquaresIcon = ({ color, width, display }) => (
  <IconWrapper width={width} display={display}>
    <svg width="100%" height="100%" viewBox="0 0 16 16">
      <path
        d="M9,9h3v3H9V9z M9,4h3v3H9V4z M4,9h3v3H4V9z M4,4h3v3H4V4z"
        fill={color}
      />
    </svg>
  </IconWrapper>
)

GridSquaresIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  display: PropTypes.string,
}

GridSquaresIcon.defaultProps = {
  color: theme.colors.coaldark,
  width: [],
  display: 'inline-block',
}

export default GridSquaresIcon
