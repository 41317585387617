import React from 'react'
import PropTypes from 'prop-types'
import theme from 'styles/theme'
import IconWrapper from './IconWrapper'

const SandwichIcon = ({ color, width, display }) => (
  <IconWrapper width={width} display={display}>
    <svg width="100%" height="100%" viewBox="0 0 24 24">
      <path d="M0,17h24v1H0V17z M0,6h24v1H0V6z" fill={color} />
    </svg>
  </IconWrapper>
)

SandwichIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  display: PropTypes.string,
}

SandwichIcon.defaultProps = {
  color: theme.colors.coaldark,
  width: [],
  display: 'inline-block',
}

export default SandwichIcon
