import styled from 'styled-components'
import {
  textAlign,
  height,
  display,
  minHeight,
  position,
  top,
  left,
} from 'styled-system'
import { Flex, Box } from 'rebass'
import theme from 'styles/theme'

export const HeaderMenuWrapper = styled(Box)`
  ${textAlign}
  ${height}
  ${display}
  ${position}
  ${top}
  ${left}
  overflow-y: visible;

  ${(props) =>
    props.shouldForceSandwichMenu === false &&
    `
    ${theme.mediaQueries.medium} {
      overflow-y: hidden;
    }
  `}
`
export const HeaderMenuList = styled(Flex)`
  ${height}
  ${minHeight}
  list-style: none;
  padding: 0;
  margin: 0;
  transition: padding-top 0.3s ease-in-out;
  will-change: padding-top;
  overflow: visible;
`
