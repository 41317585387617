import React, { Component } from 'react'
import PropTypes from 'prop-types'
import PopupContext from './Popup.context'
import initialState from './Popup.initialState'

export default class PopupProvider extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    initial: PropTypes.shape({
      cta: PropTypes.shape({
        label: PropTypes.string,
        url: PropTypes.string,
      }),
      dismiss: PropTypes.string,
      headline: PropTypes.string,
      icon: PropTypes.string,
    }),
  }

  static defaultProps = {
    children: null,
    initial: null,
  }

  constructor(props) {
    super(props)
    // set the default state
    this.defaultState = initialState
  }

  isConsentBannerShowing = () =>
    document && !!document.getElementById('_evidon_banner')

  // reset popup content func
  clearPopupContextState = () => {
    this.setState(initialState)
  }

  setPopupContent = ({
    afterSetPopupContent,
    beforeSetPopupContent,
    ...nextProps
  }) => {
    if (beforeSetPopupContent) beforeSetPopupContent()
    const nextState = Object.assign({}, this.defaultState, nextProps)
    this.setState(nextState, () => {
      const { timer = 0 } = this.state
      setTimeout(() => {
        const { waiting, type } = this.state
        // when we're ready to pull the trigger, check the modal state
        const modal = window.modalIsOpened
        const cookieBannerAware =
          this.isConsentBannerShowing() && type === 'membershipPopUp'
        // we need to check if there is a modal waiting,
        // required for preventing a popup showing on a page where it shouldn't be seen after
        // the timer is kicked off

        // if modal is open or a user navigated to a non-popup page since the timout started,
        // clear this off and abandon popup
        if (modal || !waiting || cookieBannerAware) {
          const { beforeClose } = nextProps
          // this will result in a short cookie being silently set
          beforeClose(true)
          // then clear off our state
          this.clearPopupContextState()
          // else
        } else {
          // set our state as normal
          this.setState({ showing: true }, afterSetPopupContent)
        }
      }, timer * 1000)
    })
  }

  close = (useCallbacks = true) => {
    const { beforeClose } = this.state
    if (useCallbacks) {
      beforeClose()
    }

    // we need to clear out state when we're done, afterClose not being used so removed
    this.clearPopupContextState()
  }

  render() {
    const { children } = this.props

    const {
      close,
      setPopupContent,
      clearPopupContextState,
      isConsentBannerShowing,
    } = this
    const contextValue = {
      ...this.state,
      close,
      setPopupContent,
      clearPopupContextState,
      isConsentBannerShowing,
    }

    return (
      <PopupContext.Provider value={contextValue}>
        {children}
      </PopupContext.Provider>
    )
  }
}
