const initialState = {
  close: () => {},
  cta: {
    label: '',
    url: '',
  },
  dismiss: '',
  headline: '',
  icon: '',
  waiting: false,
  setPopupContent: () => {},
  showing: false,
  backgroundImage: {},
  condensedVersion: false,
  hexCode: '',
  legalCopy: '',
  type: 'default',
  secondaryCopy: '',
  cookieHash: '',
  bodyCopy: '',
  beforeClose: () => {},
  body: '',
  timer: '',
  active: false,
  cookieTimeout: undefined,
}

export default initialState
