import { rem } from 'polished'
import styled from 'styled-components'
import theme from 'styles/theme'
import Button from 'components/Button'
import { Title26, Title32 } from 'components/Typography'
import { GatsbyImage } from 'gatsby-plugin-image'

const setCondensedPopUp = (isCondensed) =>
  isCondensed &&
  `align-items: flex-end; 
    justify-content: flex-end; 
    padding-bottom: 0; 
    top: auto; height: 0; 
    bottom: 0; 
    background: none;`

export const Wrapper = styled.aside`
  align-items: center;
  background: rgba(69, 69, 69, 0.45);
  color: ${theme.colors.sagedark};
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 50px 20px;
  position: fixed;
  text-align: center;
  top: 0;
  transition: opacity 0.3s ease, visibility 0.3s;
  width: 100%;
  z-index: 99;

  ${({ showing }) => `
    opacity: ${showing ? '1' : '0'};
    visibility: ${showing ? 'visible' : 'hidden'};
  `}

  ${({ condensedVersion }) => setCondensedPopUp(condensedVersion)};

  ${theme.mediaQueries.mobile} {
    justify-content: center;
  }
`

export const BackgroundImage = styled(GatsbyImage)`
  position: absolute !important;
  height: 100%;
  width: 100%;
`

export const PositionWrapper = styled.div`
  position: absolute;

  ${({ condensedVersion }) => `
    max-width: ${condensedVersion ? '335px' : '460px'};
  `};
`

export const Inner = styled.div`
  display: block;
  position: relative;
  z-index: 100;
  flex-direction: column;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  ${({ type }) => `
    padding: ${
      type === 'membershipPopUp' ? '20px 20px 30px 40px' : '21px 20px 8px'
    };
    max-height: ${type === 'membershipPopUp' ? '100vh' : '100%'};
    max-width: ${type === 'membershipPopUp' ? '460px' : '464px'};
  `}
  width: 100%;

  ${theme.mediaQueries.medium} {
    display: flex;
    overflow-y: hidden;
    padding: 21px 40px 8px;
  }

  ${({ condensedVersion }) => `
    max-width: ${condensedVersion ? '335px' : '460px'};
    padding: 20px;
  `};

  @media screen and (max-height: 400px) {
    max-height: unset;
  }
`

export const Closer = styled.button`
  appearance: none;
  background: none;
  border-radius: 0;
  border: 0 none;
  box-shadow: none;
  color: inherit;
  cursor: pointer;
  display: block;
  flex: none;
  margin: 0 0 9px auto;
  outline: none;
  padding: 0;

  ${theme.mediaQueries.medium} {
    margin: 0 -20px 19px auto;
  }

  ${({ type, hexcode }) => `
    background-color: ${hexcode && hexcode};
    border-radius: ${type === 'membershipPopUp' ? '50%' : '0'};
    width: ${type === 'membershipPopUp' ? '40px' : '21px'};
    height: ${type === 'membershipPopUp' ? '40px' : '21px'};
  `}

  &::before {
    content: '';
    display: block;
    height: 100%;
    position: relative;
    transform: rotate(45deg);
    width: 100%;

    ${({ color }) => `
      background: ${
        color
          ? `linear-gradient(${color}, ${color}) 50% 50% / 1px 100%
        no-repeat,
      linear-gradient(${color}, ${color}) 50% 50% / 100% 1px no-repeat`
          : `linear-gradient(currentColor, currentColor) 50% 50% / 1px 100%
        no-repeat,
      linear-gradient(currentColor, currentColor) 50% 50% / 100% 1px no-repeat;`
      };
    `}

    ${({ type }) => `
      width: ${type === 'membershipPopUp' ? '50%' : '100%'};
      height: ${type === 'membershipPopUp' ? '50%' : '100%'};
      margin: 0 auto;
    `}
  }
`

export const Icon = styled.div`
  background: ${theme.colors.sagedark};
  border-radius: 100%;
  color: ${theme.colors.white};
  flex: none;
  font: 700 20px/42px ${theme.textStyles.title60.fontFamily};
  height: 40px;
  margin: 0 auto 22px;
  text-align: center;
  width: 40px;
`

export const Spacing = styled.div`
  ${({ condensedVersion }) => `
    height: ${condensedVersion ? '1.5625rem' : '1.9375rem'};
  `}
`

export const Headline = styled(Title26)`
  flex: none;
  ${({ type }) => `
    padding: ${type === 'membershipPopUp' ? '0 11px' : '0 20px'};
  `}

  ${({ condensedVersion }) =>
    `margin: ${condensedVersion ? '0px' : '0 0 10px'};
  `}

  ${theme.mediaQueries.medium} {
    padding: 0;
  }

  ${({ hexCode, type }) => `
    color: ${type === 'membershipPopUp' && `${hexCode}`};
  `}
`

export const HeadlineMembership = styled(Title32)`
  flex: none;
  ${({ type }) => `
    padding: ${type === 'membershipPopUp' ? '0 11px' : '0 20px'};
  `}

  ${({ condensedVersion }) =>
    `margin: ${condensedVersion ? '0px' : '0 0 10px'};
  `}

  ${theme.mediaQueries.medium} {
    padding: 0;
  }

  ${({ hexCode, type }) => `
    color: ${type === 'membershipPopUp' && `${hexCode}`};
  `}
`

export const Body = styled.div`
  color: ${theme.colors.sagemedium};
  flex: 1 1 auto;
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  -webkit-overflow-scrolling: touch;
  overflow-y: unset;
  text-align: inherit;

  ${({ type }) => `
    padding: ${type === 'membershipPopUp' ? '0 11px' : '0 20px'};
  `}

  ${theme.mediaQueries.medium} {
    overflow-y: unset;
    padding: 0;
  }

  ${({ hexCode, type }) => `
    color: ${type === 'membershipPopUp' && `${hexCode}`};
  `}

  ${({ secondaryCopy }) => `
    margin: 0 auto;
    margin-top: ${secondaryCopy ? '1.875rem' : '0'};
    max-width: ${secondaryCopy ? '259px' : ''};
    width: ${secondaryCopy ? '100%' : ''};
    overflow-y: ${secondaryCopy ? 'unset' : ''};
  `}

  ${({ top }) => `
    margin-top: ${top && `${top}rem`};
  `}

  ${({ size }) => `
    font-size: ${size && `${size}rem`};
  `}

  ${({ legalCopy, condensedVersion }) => `
    margin-top: ${legalCopy && condensedVersion && '2.125rem'};
  `}

  p,
  ul,
  ol {
    font: inherit;
  }

  p {
    a {
      ${({ secondaryCopy }) => `
        display: ${secondaryCopy ? 'block' : 'inline-block'};
        padding-top: ${secondaryCopy ? `1.25rem` : '0rem'};
        font-size: 0.75rem;
      `}
    }
  }

  @media screen and (max-height: 400px) {
    p {
      max-height: 60px;
      overflow: scroll;
    }
  }
`

export const Actions = styled.div`
  flex: none;
  ${({ type }) => `
    margin-top: ${type === 'membershipPopUp' ? '20px' : '30px'};
  `}

  .popup-cta {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
  }
`

export const Dismiss = styled(Button)`
  margin-top: 10px;
`
