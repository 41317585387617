import styled from 'styled-components'
import { Box } from 'rebass'
import {
  borders,
  position,
  display,
  height,
  maxHeight,
  minHeight,
  maxWidth,
  themeGet,
  textAlign,
  backgroundColor,
  margin,
  style,
  overflow,
  paddingBottom,
  paddingTop,
} from 'styled-system'

import { rem } from 'polished'

import theme from 'styles/theme'

export const Block = styled(Box)`
  ${borders}
  ${display}
  ${position}
  ${height}
  ${maxHeight}
  ${minHeight}
  ${maxWidth}
  ${overflow}
`

export const Divider = styled(Block)`
  margin: 0;
  height: 1px;
  border-style: solid;
  border-width: 1px 0 0 0;
  border-color: ${themeGet('colors.alto')};
  width: 100%;
  ${maxWidth}
  ${borders}
  ${display}
`

export const Container = styled(Box)`
  max-width: ${themeGet('containterMaxWidth')};
  margin: 0 auto;
  ${textAlign}
  ${backgroundColor}
  ${maxWidth}
  ${borders}
  ${margin}
  ${paddingBottom}
  ${paddingTop}
  ${(props) =>
    props.borderBottom
      ? `border-bottom: 1px solid ${theme.colors.sagelight};`
      : ''}
  ${(props) =>
    props.childrenGrid
      ? `${theme.mediaQueries.small}{ display: grid; grid-template-columns: 1fr 1fr; grid-gap: ${theme.space[4]}; }`
      : ''}
  ${(props) =>
    props.phoneContainer
      ? `display: grid; grid-template-columns: 1fr 2.5fr; grid-gap: ${theme.space[2]};`
      : ''}
`

export const float = style({
  prop: 'float',
  cssProperty: 'float',
})

export const transform = style({
  prop: 'transform',
  cssProperty: 'transform',
})

export const verticalAlign = style({
  prop: 'verticalAlign',
  cssProperty: 'verticalAlign',
})

export const textTransform = style({
  prop: 'textTransform',
  cssProperty: 'textTransform',
})

export const fill = style({
  prop: 'fill',
  cssProperty: 'fill',
})

export const BoxedItem = styled.li`
  display: inline-block;
  position: relative;
  top: 1px;
  left: 1px;
  margin-left: -1px;
  margin-top: -1px;
  border: 1px solid ${themeGet('colors.sagelight')};
`

export const BottomWhiteCurvedEdge = styled.div`
  position: absolute;
  width: 100%;
  bottom: -1px;
  left: 0;
  height: 2.5vw;
  padding: 0;
  margin: 0;
  background-image: url("data:image/svg+xml,%3Csvg preserveAspectRatio='none' enable-background='new 0 0 1424 20' version='1.1' viewBox='0 0 1424 20' xml:space='preserve' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23ffffff' d='M0,20.3h1424V0c-186.7,10.2-424,15.3-712,15.3S186.7,10.2,0,0V20.3z'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: 100%;
  transform: scaleY(1.1);

  ${theme.mediaQueries.medium} {
    height: 2vw;
  }

  ${theme.mediaQueries.large} {
    height: 1.5vw;
  }

  ${theme.mediaQueries.xlarge} {
    height: 1vw;
  }
`

export const TopWhiteCurvedEdge = styled.div`
  position: absolute;
  width: 100%;
  top: -1px;
  left: 0;
  height: 2.5vw;
  padding: 0;
  margin: 0;
  background-image: url("data:image/svg+xml,%3Csvg preserveAspectRatio='none' enable-background='new 0 0 1424 20' version='1.1' viewBox='0 0 1424 20' xml:space='preserve' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23ffffff' d='M1424,0H0v20.3C186.7,10.1,424,5,712,5s525.3,5.1,712,15.3V0z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: 100%;

  ${theme.mediaQueries.medium} {
    height: 2vw;
  }

  ${theme.mediaQueries.large} {
    height: 1.5vw;
  }

  ${theme.mediaQueries.xlarge} {
    height: 1vw;
  }
`

export const TopPaddedPage = styled.div`
  padding-top: 32px;

  ${theme.mediaQueries.large} {
    padding-top: 200px;
  }
`
export const ReducedTopPaddedPage = styled.div`
  padding-top: 32px;
`

const block = `
  display: block;
  width: 100%;
  position: relative;
`

export const Spacing120 = styled.div`
  ${block}
  height: ${rem(120)};
`

export const Spacing100 = styled.div`
  ${block}
  height: ${rem(60)};

  ${theme.mediaQueries.medium} {
    height: ${rem(100)};
  }
`

export const Spacing60 = styled.div`
  ${block}
  height: ${rem(60)};
`

export const Spacing50 = styled.div`
  ${block}
  height: ${rem(30)};

  ${theme.mediaQueries.medium} {
    height: ${rem(50)};
  }
`

export const Spacing48 = styled.div`
  ${block}
  height: ${rem(38)};

  ${theme.mediaQueries.medium} {
    height: ${rem(48)};
  }
`

export const Spacing40 = styled.div`
  ${block}
  height: ${rem(30)};

  ${theme.mediaQueries.medium} {
    height: ${rem(40)};
  }
`

export const Spacing38 = styled.div`
  ${block}
  height: ${rem(24)};

  ${theme.mediaQueries.medium} {
    height: ${rem(37)};
  }
`

export const Spacing30 = styled.div`
  ${block}
  height: ${rem(30)};
`

export const Spacing20 = styled.div`
  ${block}
  height: ${rem(20)};
`

export const Spacing16 = styled.div`
  ${block}
  height: ${rem(12)};

  ${theme.mediaQueries.medium} {
    height: ${rem(16)};
  }
`

export const Spacing10 = styled.div`
  ${block}
  height: ${rem(10)};
`

export const frostGlass = `
  background: linear-gradient(248deg, rgba(255, 0, 0, 0.17) 1.52%, rgba(85, 105, 103, 0.50) 96.99%);
  box-shadow: -3px -3px 150px 0px rgba(255, 255, 255, 0.10) inset, 3px 3px 150px 0px rgba(66, 66, 66, 0.10) inset;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
`

export const buttonShadow = 'box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);'
