import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper } from './Trigger.style'

const Trigger = ({ logo, open }) => (
  <Wrapper
    onClick={open}
    type="button"
    dangerouslySetInnerHTML={{ __html: logo }}
  />
)

Trigger.propTypes = {
  logo: PropTypes.string.isRequired,
  open: PropTypes.func.isRequired,
}

export default Trigger
