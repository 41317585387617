import colors, { palettes } from './colors'
import mediaQueries from './mediaQueries'
import breakpoints from './breakpoints'
import maxWidths, { containterMaxWidth } from './maxWidths'
import fontSizes from './fontSizes'
import space from './space'
import textStyles from './textStyles'

export default {
  colors,
  palettes,
  breakpoints,
  mediaQueries,
  maxWidths,
  containterMaxWidth,
  fontSizes,
  space,
  textStyles,
}
