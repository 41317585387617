import { rem } from 'polished'
import styled, { keyframes } from 'styled-components'
import theme from 'styles/theme'
import { Body16, Eyebrow as ThemeEyebrow, Title42 } from 'components/Typography'
import {
  Inner as ListInner,
  Wrapper as ListWrapper,
} from './OnboardingQList.style'

export const Inner = styled(ListInner)`
  background: none;

  // Forces override of ListInner styles
  @media all {
    padding-bottom: 30px;
  }
`

export const BgSlider = styled.div`
  &.slick-slider {
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .slick-list,
  .slick-track,
  .slick-slide,
  .slick-slide > div {
    height: 100%;
  }

  [dir='rtl'] & {
    .slick-track {
      flex-flow: row-reverse;
    }
  }
`

export const BgSlide = styled.div`
  background-color: ${theme.colors.sagemedium};
  height: 100%;

  ${({ bgColor, bgImg, bgSize }) =>
    !!bgImg &&
    `
    background: url(${bgImg}) 100% 100% / ${60 *
      (bgSize / 1000)}% auto no-repeat;
    background-color: ${
      bgColor ? `#${bgColor.trim().replace(/^#/, '')}` : theme.colors.sagemedium
    };

    ${theme.mediaQueries.medium} {
      background-size: ${500 * (bgSize / 1000)}px auto;
    }
  `}

  .step-enter & {
    background-position: calc(100% + 60vw) 100%;

    ${theme.mediaQueries.medium} {
      background-position: calc(100% + 600px) 100%;
    }
  }

  .step-enter-active &,
  .step-enter-done & {
    background-position: 100% 100%;
    transition: background-position 0.8s cubic-bezier(0.23, 1, 0.32, 1) 0.6s;
  }
`

export const Heading = styled.div``

export const Eyebrow = styled(ThemeEyebrow)`
  margin-bottom: ${rem(15)};
`

export const Question = styled(Title42)`
  font-size: ${rem(32)};
`

export const Arrow = styled.svg`
  display: block;
  margin: 20px 0 30px;
`

export const AnswerSliderWrap = styled.div`
  position: relative;

  .step-enter & {
    transform: translateX(50%) translateX(50vw);
  }

  .step-enter-active &,
  .step-enter-done & {
    transform: translateX(0);
    transition: transform 0.8s cubic-bezier(0.23, 1, 0.32, 1) 0.6s;
  }

  .slick-dots {
    transition: opacity 0.2s ease 0.2s, visibility 0.4s linear;
  }

  .slick-dots {
    display: flex !important;
    justify-content: center;
    left: 0;
    list-style: none outside none;
    margin: 0 auto;
    max-width: 486px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 111px;
    width: 100%;

    ${theme.mediaQueries.medium} {
      top: 220px;
    }

    li {
      opacity: 0.5;

      &.slick-active {
        opacity: 1;
      }
    }

    button {
      appearance: none;
      background: none;
      border-radius: 0;
      border: 0 none;
      box-shadow: none;
      color: transparent;
      font-size: 0;
      outline: none;
      padding: 1.5px;
      white-space: normal;

      ${theme.mediaQueries.medium} {
        padding: 3px;
      }

      &::before {
        background: ${theme.colors.white};
        border-radius: 100%;
        content: '';
        display: block;
        height: 5px;
        width: 5px;

        ${theme.mediaQueries.medium} {
          height: 9px;
          width: 9px;
        }
      }
    }
  }
`

export const AnswerSlider = styled.div`
  margin-left: calc(50% - 50vw);
  position: relative;
  text-align: center;
  transition: height 0.4s ease, transform 0.4s ease;
  width: 100vw;

  ${theme.mediaQueries.smallOnly} {
    margin-left: calc(50% - (100vw / 4));
    width: calc(100vw / 2);

    ${({ onOverview }) =>
      !!onOverview &&
      `
      transform: translateX(-50vw);

      [dir=rtl] & {
        transform: translateX(50vw);
      }
    `}
  }

  [dir='rtl'] & {
    margin-left: auto;
    margin-right: calc(50% - 50vw);

    ${theme.mediaQueries.smallOnly} {
      margin-right: calc(50% - (100vw / 4));
    }

    .slick-track {
      flex-flow: row-reverse;
    }
  }

  * {
    outline: none;
  }

  .slick-list {
    transition: min-height 0.4s ease;
  }

  .slick-list,
  .slick-track {
    min-height: 100%;
    overflow: visible;
  }

  .slick-slide {
    &:not(.slick-active) {
      height: 0;
    }
  }
`

export const AnswerSlide = styled.div`
  outline: none;
`

const Wiggle = keyframes`
  20% {
    transform: translateX(15%);
  }
  45% {
    transform: translateX(-15%);
  }
  70% {
    animation-timing-function: ease-out;
    transform: translateX(15%);
  }
`

export const Item = styled.div`
  align-items: center;
  color: ${theme.colors.white};
  cursor: pointer;
  display: flex;
  height: 160px;
  justify-content: center;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  text-transform: uppercase;
  width: 160px;

  ${theme.mediaQueries.medium} {
    height: 300px;
    padding-bottom: 40px;
    width: 300px;
  }

  ${({ cirColor }) =>
    !!cirColor &&
    `
    background: #${cirColor.trim().replace(/^#/, '')};
  `}

  ${({ dragging }) =>
    dragging &&
    `
    pointer-events: none;
  `}

  .step-enter-done .slick-slide[data-index="0"] & {
    animation: ${Wiggle} 1s ease-in-out;
  }
`

export const Blob = styled.svg.attrs(() => ({
  xmlns: 'http://www.w3.org/2000/svg',
  viewBox: '0 0 300 300',
  width: 300,
  height: 300,
}))`
  display: block;
  height: auto;
  left: 0;
  overflow: visible;
  position: absolute;
  top: 0;
  width: 100%;

  ${({ cirColor }) =>
    !!cirColor &&
    `
    fill: #${cirColor.trim().replace(/^#/, '')};
  `}
`

export const ItemText = styled(Body16)`
  font-size: ${rem(12)};
  letter-spacing: 0.1em;
  line-height: 1.5;
  margin: 0;
  max-width: 115px;
  position: relative;

  ${theme.mediaQueries.medium} {
    font-size: ${rem(16)};
  }
`

export const SlickArrow = styled.button`
  appearance: none;
  background: ${theme.colors.white};
  border-radius: 100%;
  border: 0 none;
  box-shadow: none;
  color: transparent;
  font-size: 0;
  height: 40px;
  outline: none;
  padding: 0;
  position: absolute;
  top: 130px;
  transition: opacity .2s ease .2s, visibility .4s linear;
  width: 40px;
  z-index: 1;

  ${theme.mediaQueries.smallOnly} {
    display: none !important;
  }

  &::before {
    border: 1px solid ${theme.colors.coaldark};
    border-width: 0 0 1px 1px;
    bottom: 0;
    content: '';
    display: block;
    height: 14px;
    left: 8px;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    transform: rotate(45deg);
    width: 14px;
  }

  ${({ dir }) =>
    dir === 'prev' &&
    `
    left: calc(50% - 232px);
  `}

  ${({ dir }) =>
    dir === 'next' &&
    `
    right: calc(50% - 232px);
    transform: scaleX(-1);
  `}

  ${({ greyed }) =>
    !!greyed &&
    `
    background: ${theme.colors.alabaster};
    opacity: 0.7;
    pointer-events: none;

    &::before {
      border-color: ${theme.colors.gray};
    }
  `}
`

export const Wrapper = styled(ListWrapper)`
  position: relative;

  ${({ remaining }) =>
    remaining === 0 &&
    `
    ${SlickArrow},
    .slick-dots {
      opacity: 0;
      transition: none;
      visibility: hidden;
    }
  `}
`
