import React from 'react'
import PropTypes from 'prop-types'
import theme from 'styles/theme'
import IconWrapper from './IconWrapper'

const ArrowIcon = ({ color, width, display, transform }) => (
  <IconWrapper
    width={width}
    height="100%"
    display={display}
    transform={transform}>
    <svg width="100%" height="100%" viewBox="0 0 30 8">
      <path
        d="m27.4 4h-27.3v-1h27.3l-2-2 0.7-0.7 3.2 3.2-3.2 3.2-0.7-0.7 2-2z"
        fill={color}
      />
    </svg>
  </IconWrapper>
)

ArrowIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  display: PropTypes.string,
  transform: PropTypes.string,
}

ArrowIcon.defaultProps = {
  color: theme.colors.coaldark,
  width: [],
  display: 'inline-block',
  transform: 'rotate(0)',
}

export default ArrowIcon
