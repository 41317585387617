import { rem } from 'polished'
import styled from 'styled-components'
import theme from 'styles/theme'
import Link from 'components/Link'
import { Eyebrow as ThemeEyebrow } from 'components/Typography'
import { GatsbyImage } from 'gatsby-plugin-image'

export const Wrapper = styled.article`
  text-align: center;
`

export const Eyebrow = styled(ThemeEyebrow)`
  font-size: ${rem(12)};
  margin: 20px 0;
`

export const Body = styled.div`
  margin: 0 auto;
  max-width: 480px;
  text-align: inherit;
`

export const Picture = styled.picture`
  display: block;
  margin-top: 20px;
`

export const Image = styled(GatsbyImage)`
  display: block;
  height: auto;
  margin: 0 auto;
  height: 450px;
  max-width: 450px;
  width: 100%;
`

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 60px;

  ${theme.mediaQueries.medium} {
    margin-top: 20px;
  }
`

export const Cta = styled(Link)`
  flex: none;
  max-width: 100%;
  min-width: 200px;
  padding: 20px;
`
