import styled from 'styled-components'
import { Box, Flex } from 'rebass'
import {
  margin,
  marginTop,
  marginBottom,
  marginRight,
  width,
  height,
  display,
  overflow,
} from 'styled-system'
import theme from 'styles/theme'
import { fill } from 'styles/sharedStyle'

export const FooterContainer = styled(Box).attrs({
  role: 'contentinfo',
})``

export const FooterFlex = styled(Flex)`
  ${overflow}
`

export const SVGWrapper = styled.span`
  display: inline-block;
  ${margin}
  ${marginTop}
  ${marginBottom}
  ${marginRight}
  ${height}
  ${width}
  ${display}
  * {
    ${fill}
  }
`

export const LogoWrapper = styled(SVGWrapper)`
  html[dir='ltr'] & {
    margin-right: ${theme.space[8]};
  }

  html[dir='rtl'] & {
    margin-left: ${theme.space[8]};
  }

  ${theme.mediaQueries.small} {
    html[dir='ltr'] & {
      margin-right: 0;
    }

    html[dir='rtl'] & {
      margin-left: 0;
    }
  }

  ${theme.mediaQueries.medium} {
    html[dir='ltr'] & {
      margin-right: ${theme.space[4]};
    }

    html[dir='rtl'] & {
      margin-left: ${theme.space[4]};
    }
  }
`
