import React from 'react'
import PropTypes from 'prop-types'
import { Eyebrow } from 'components/Typography'
import FlexCenter from './FlexCenter'
import {
  Ball,
  Text,
  TextBody,
  TextButton,
  TextHeadline,
  TextInner,
} from './OnboardingText.style'

const OnboardingText = ({
  bodyCopy,
  cta1Label,
  direction,
  eyebrow,
  headline,
  isLast,
  next,
}) => (
  <FlexCenter>
    <Text>
      <TextInner direction={direction}>
        {!!eyebrow && <Eyebrow>{eyebrow}</Eyebrow>}
        {!!headline && <TextHeadline>{headline}</TextHeadline>}
        {!!bodyCopy && <TextBody>{bodyCopy}</TextBody>}
        {!!cta1Label && (
          <TextButton onClick={next} level="tertiary">
            {cta1Label}
          </TextButton>
        )}
      </TextInner>
      {!!isLast && (
        <Ball>
          <path d="m26.9519 4.2819c-12.5699 7.4476-21.9445 20.0852-25.415 34.2609-3.4708 14.1757-.9923 29.7055 6.7191 42.1026 3.1247 5.0238 7.1764 9.6274 12.39 12.4336 6.2141 3.345 13.5306 3.882 20.5902 3.9188 9.1719.048 18.4931-.6602 27.091-3.8495 8.5976-3.1892 16.4701-9.1133 20.4482-17.3644 2.8334-5.8763 3.5507-12.5253 3.9397-19.0356.504-8.4397.5157-17.0053-1.4523-25.2282-1.9678-8.2232-6.0718-16.1466-12.6475-21.4737-7.474-6.0551-17.3334-8.225-26.8842-9.4184-8.8958-1.1116-18.741-1.2914-25.8669 4.1401z" />
        </Ball>
      )}
    </Text>
  </FlexCenter>
)

OnboardingText.propTypes = {
  bodyCopy: PropTypes.string,
  cta1Label: PropTypes.string,
  direction: PropTypes.number,
  eyebrow: PropTypes.string,
  headline: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  isLast: PropTypes.bool,
  next: PropTypes.func,
}

OnboardingText.defaultProps = {
  bodyCopy: null,
  cta1Label: null,
  direction: 0,
  eyebrow: null,
  headline: null,
  isLast: false,
  next: () => {},
}

export default OnboardingText
