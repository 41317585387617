import React from 'react'
import PropTypes from 'prop-types'
import theme from 'styles/theme'

import { Container, BannerText } from './Banner.styles'

const Banner = ({ backgroundColor, textColor, message, transparent }) => {
  if (!message) return null

  return (
    <Container backgroundColor={backgroundColor} transparent={transparent}>
      <BannerText textColor={textColor}>{message}</BannerText>
    </Container>
  )
}

Banner.propTypes = {
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  message: PropTypes.string,
  transparent: PropTypes.bool,
}

Banner.defaultProps = {
  backgroundColor: theme.colors.red,
  textColor: theme.colors.sagefeather,
  message: '',
  transparent: false,
}

export default Banner
