import styled from 'styled-components'
import theme from 'styles/theme'
import { Flex, Box } from 'rebass'
import { display } from 'styled-system'

export const Wrapper = styled(Flex)`
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  flex-direction: row;
  margin: 20px auto 40px;
  max-width: 580px;
  position: relative;
  width: 100%;

  ${theme.mediaQueries.small} {
    margin-bottom: 60px;
  }
`

export const TabButton = styled.button`
  align-self: stretch;
  appearance: none;
  background: none;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  color: inherit;
  display: flex;
  outline: none;
  padding: 0;
  white-space: normal;
  ${({ width }) => `
    width: ${width * 100}%;
  `}

  div {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
`

export const Tab = styled(Box)`
  border-bottom: 2px solid transparent;
  cursor: pointer;
  display: block;
  padding: ${theme.space[1]} 5px;
  position: relative;
  text-align: center;
  top: 1px;
  transition: border-color 0.2s ease-in-out;
  will-change: border-color;
  z-index: 1;

  ${({ width }) =>
    width &&
    `
    width: ${width * 100}%;
  `}

  ${({ active }) =>
    active &&
    `
    border-color: ${theme.colors.white};
  `}

  ${theme.mediaQueries.small} {
    border-color: transparent;
  }

  span {
    color: ${(props) =>
      props.active ? theme.colors.white : 'rgba(255, 255, 255, .5)'};
    transition: color 0.2s ease-in-out;
    will-change: color;
  }
`

export const TabSelected = styled.div`
  background: ${theme.colors.white};
  bottom: -1px;
  ${display}
  height: 2px;
  left: 0;  
  opacity: 0;
  position: absolute;
  width: 100%;

  ${(props) =>
    props.hasActiveTab &&
    `
    opacity: 1;
    transition: transform 0.4s ease-in-out, opacity 0.4s 0.4s ease-in-out;
  `}

  ${(props) =>
    !props.hasActiveTab &&
    props.hoveredItem > -1 &&
    `
    opacity: 1;
    transition: transform 0.4s ease-in-out, opacity 0.4s 0.4s ease-in-out;
  `}

  ${(props) => `
    transform: translate3d(${props.hoveredItem * 100}%,0,0);
    width: ${props.itemWidth * 100}%;
  `}

  html[dir='rtl'] & {
    right: 0;
    ${(props) => `transform: translate3d(${props.hoveredItem * -100}%,0,0);`}
  }
`
