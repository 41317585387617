import React from 'react'
import PropTypes from 'prop-types'
import theme from 'styles/theme'
import IconWrapper from './IconWrapper'

const FilterIcon = ({ color, width, display }) => (
  <IconWrapper width={width} display={display}>
    <svg
      preserveAspectRatio="xMaxYMid slice"
      viewBox="0 0 16 18"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill="transparent"
        stroke={color}
        strokeWidth="1.87729"
        strokeLinecap="round"
        strokeLinejoin="round"
        id="Vector"
        d="M13.7418 1.55627C14.6596 1.55627 15.4105 2.30719 15.4105 3.22497V5.06054C15.4105 5.72803 14.9933 6.56238 14.5762 6.97955L10.9885 10.1501C10.4878 10.5673 10.1541 11.4016 10.1541 12.0691V15.6568C10.1541 16.1574 9.82037 16.8249 9.40319 17.0752L8.2351 17.8261C7.15045 18.4936 5.64862 17.7427 5.64862 16.4077V11.9857C5.64862 11.4016 5.31488 10.6507 4.98114 10.2335L1.81061 6.89612C1.39343 6.47894 1.05969 5.72802 1.05969 5.22741V3.30841C1.05969 2.30719 1.81061 1.55627 2.72839 1.55627H10.2375M7.34235 1.55627L3.229 8.14764"
      />
    </svg>
  </IconWrapper>
)

FilterIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  display: PropTypes.string,
}

FilterIcon.defaultProps = {
  color: theme.colors.coaldark,
  width: [],
  display: 'inline-block',
}

export default FilterIcon
