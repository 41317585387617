// TODO come back to check breakpoints

// @ts-nocheck
/* eslint-disable prefer-destructuring */
// https://medium.freecodecamp.org/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862
// https://github.com/styled-system/styled-system/blob/master/docs/responsive-styles.md

export const bp = ['600px', '900px', '1200px', '1800px']

export const deviceBreakpoints = {
  iPad: {
    portrait: {
      width: '768px',
      height: '1024px',
    },
    landscape: {
      width: '1024px',
      height: '768px',
    },
  },
}

const breakpoints = [...bp]
breakpoints.default = '0px'
breakpoints.sm = bp[0]
breakpoints.md = bp[1]
breakpoints.lg = bp[2]
breakpoints.xl = bp[3]
breakpoints.ipad_portrait = deviceBreakpoints.iPad.portrait
breakpoints.ipad_landscape = deviceBreakpoints.iPad.landscape

export default breakpoints
