import { rem } from 'polished'
import styled from 'styled-components'
import theme from 'styles/theme'
import { Title60 } from 'components/Typography'

export const Curve = styled.svg.attrs(() => ({
  xmlns: 'http://www.w3.org/2000/svg',
  viewBox: '0 0 375 50',
  preserveAspectRatio: 'none',
  width: 375,
  height: 50,
}))`
  display: block;
  fill: ${theme.colors.sagedark};
  height: 50px;
  width: 100%;
  overflow: hidden;
`

export const Body = styled.div`
  background: ${theme.colors.sagedark};
  color: ${theme.colors.white};
  padding: 30px 40px 2px;
  text-align: center;

  ${theme.mediaQueries.medium} {
    padding: 12px 0 2px;
  }
`

export const Headline = styled(Title60)`
  color: inherit;
  font-size: ${rem(42)};
  letter-spacing: -0.05em;
  margin: 0 auto;
  max-width: 500px;

  ${theme.mediaQueries.medium} {
    font-size: ${rem(60)};
  }
`
