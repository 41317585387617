import styled from 'styled-components'
import { hideVisually } from 'polished'
import { Box } from 'rebass'
import theme from 'styles/theme'

export const SocialListItem = styled(Box)`
  margin: ${theme.space[1]} 1rem;

  ${theme.mediaQueries.medium} {
    margin: 0 12px;
  }

  a {
    display: block;
  }
`

export const SocialListItemLabel = styled.span`
  ${hideVisually()};
`
