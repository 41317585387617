import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import { navigate } from 'gatsby'
import { navigate as navigateReach } from '@reach/router'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import path from 'path'
import params from 'utils/params'
import usePrev from 'utils/usePrev'
import { urlJoiner } from 'utils/urlUtils'
import Header from '../Header'
import OnboardingText from './OnboardingText'
import OnboardingQList from './OnboardingQList'
import OnboardingQCarousel from './OnboardingQCarousel'
import { Pager } from '../Header/Header.style'
import {
  ConfirmBtn,
  ConfirmModal,
  ConfirmModalBtn,
  StepTransitionGroup,
  StepWrapper,
  Wrapper,
} from './Onboarding.style'

const Onboarding = ({
  allMemorableDays,
  background: {
    desktop: { file: { url: backgroundDesktop } = {} } = {},
    mobile: { file: { url: backgroundMobile } = {} } = {},
  } = {},
  confirmation,
  forwarding,
  intro,
  locale,
  location,
  questions,
  village: { villageSlug, home: [{ header = {} } = {}] = [] } = {},
}) => {
  const locationParams = params.decode(location && location.search)
  const { step: paramStep = 0 } = locationParams || {}
  const step = Number(paramStep)
  const prevStep = usePrev(step)
  const { answers: paramAnswers = '' } = locationParams || {}
  const answers = paramAnswers.split(',')
  const next = () => {
    navigateReach(params.encode({ step: step + 1 }, window.location.search))
  }
  const setAnswers = async (idx, value, options) => {
    const { advance, callback } = Object.assign(
      {
        advance: false,
        callback: () => {},
      },
      options || {}
    )
    const extra = advance ? { step: step + 1 } : {}
    const nextAnswers = Array.from(answers)
    nextAnswers[idx] = value

    await navigateReach(
      params.encode(
        {
          answers: nextAnswers.join(','),
          ...extra,
        },
        location.search
      )
    )

    callback()
  }
  const [edit, setEdit] = useState(null)
  const homeUrl = path.join(villageSlug, locale)
  const logo =
    header &&
    header.logoIcon &&
    header.logoIcon.code &&
    header.logoIcon.code.code

  const confirmationFormatted = (() => {
    let { headline } = confirmation
    headline = headline
      .replace(/%(\d)%/g, '%%%$1%%%')
      .split('%%%')
      .map((s) => {
        let snippet = s
        if (s.match(/^\d$/)) {
          const idx = s - 1
          const { answers: qAnswers = [] } = questions[idx] || {}
          const { sentence } =
            qAnswers.find(({ slug }) => slug === answers[idx]) || {}
          snippet = (
            <ConfirmBtn onClick={() => setEdit(idx)} key={idx.toString()}>
              {sentence}
            </ConfirmBtn>
          )
        }
        return snippet
      })

    return Object.assign({}, confirmation, { headline })
  })()

  const steps = Array.from(
    [intro, ...questions, confirmationFormatted, forwarding],
    (stepProps) => {
      const { answers: stepAnswers, format } = stepProps
      const components = {
        carousel: OnboardingQCarousel,
        list: OnboardingQList,
        text: OnboardingText,
      }
      const Component =
        stepAnswers && components[format] ? components[format] : components.text
      return {
        Component,
        stepProps,
      }
    }
  )

  const { Component, stepProps } = steps[step] || {}
  const showPager = !!questions && !!stepProps && !!stepProps.answers
  const pagerProps = {
    'data-step': step,
    'data-total': questions.length,
  }

  useEffect(() => {
    const element = document.querySelector('#onboarding')
    disableBodyScroll(element)
    if (step === steps.length - 1) {
      const day = allMemorableDays
        .filter((page) => page.answers && page.answers.length)
        .find((page) => {
          const pageAnswers = Array.from(page.answers, ({ slug }) => slug)
          return answers.every((slug) => pageAnswers.indexOf(slug) > -1)
        })
      if (day) {
        const forwardingUrl = urlJoiner([
          day.village && day.village.villageSlug,
          locale,
          day.pageType && day.pageType.slug,
          ...day.answers.map(({ slug }) => slug),
        ])

        setTimeout(() => {
          enableBodyScroll(element)
          navigate(forwardingUrl, {
            replace: true,
            state: {
              prevPathname: location.pathname,
            },
          })
        }, 4400)
      }
    }
  }, [step])

  return (
    <Wrapper id="onboarding" background={[backgroundMobile, backgroundDesktop]}>
      <Header
        {...{
          closeUrl: location.pathname,
          homeUrl,
          logo,
          pagerProps,
          position: 'absolute',
          showPager,
        }}
      />
      <StepTransitionGroup>
        {!!Component && (
          <CSSTransition
            classNames="step"
            timeout={1000}
            appear={step === 0}
            key={step.toString()}>
            <StepWrapper direction={step - prevStep}>
              <Component
                {...{
                  direction: step - prevStep,
                  isLast: step === steps.length - 1,
                  locale,
                  next,
                  pager: <Pager {...pagerProps} position="body" />,
                  setAnswers,
                  step,
                  ...stepProps,
                }}
              />
            </StepWrapper>
          </CSSTransition>
        )}
      </StepTransitionGroup>
      {typeof edit === 'number' && (
        <ConfirmModal>
          {!!questions[edit] &&
            questions[edit].answers.map(({ answer, slug }, i) => (
              <ConfirmModalBtn
                active={slug === answers[edit]}
                onClick={() => {
                  setAnswers(edit, slug, {
                    callback: () => setEdit(null),
                  })
                }}
                key={`${slug}${i.toString()}`}>
                {answer}
              </ConfirmModalBtn>
            ))}
        </ConfirmModal>
      )}
    </Wrapper>
  )
}

Onboarding.propTypes = {
  allMemorableDays: PropTypes.arrayOf(
    PropTypes.shape({
      answers: PropTypes.arrayOf(
        PropTypes.shape({
          slug: PropTypes.string,
        })
      ),
      locale: PropTypes.string,
      pageType: PropTypes.shape({
        slug: PropTypes.string,
      }),
      village: PropTypes.shape({
        villageSlug: PropTypes.string,
      }),
    })
  ),
  background: PropTypes.shape({
    desktop: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
    mobile: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
  confirmation: PropTypes.shape({
    cta1Label: PropTypes.string,
    eyebrow: PropTypes.string,
    headline: PropTypes.string,
  }),
  forwarding: PropTypes.shape({
    eyebrow: PropTypes.string,
    headline: PropTypes.string,
  }),
  intro: PropTypes.shape({
    bodyCopy: PropTypes.string,
    cta1Label: PropTypes.string,
    eyebrow: PropTypes.string,
    headline: PropTypes.string,
  }),
  locale: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }),
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      answers: PropTypes.arrayOf(
        PropTypes.shape({
          answer: PropTypes.string,
          bgColor: PropTypes.string,
          cirColor: PropTypes.string,
          image: PropTypes.shape({
            portrait: PropTypes.shape({
              file: PropTypes.shape({
                details: PropTypes.shape({
                  image: PropTypes.shape({
                    height: PropTypes.number,
                    width: PropTypes.number,
                  }),
                }),
                url: PropTypes.string,
              }),
            }),
          }),
          sentence: PropTypes.string,
          slug: PropTypes.string,
          thumbnail: PropTypes.shape({
            portrait: PropTypes.shape({
              fixed: PropTypes.shape({
                src: PropTypes.string,
              }),
            }),
          }),
        })
      ),
      eyebrow: PropTypes.string,
      format: PropTypes.string,
      headline: PropTypes.string,
      overviewEyebrow: PropTypes.string,
      overviewHeadline: PropTypes.string,
    })
  ),
  village: PropTypes.shape({
    villageSlug: PropTypes.string,
    home: PropTypes.arrayOf(
      PropTypes.shape({
        header: PropTypes.shape({
          logoIcon: PropTypes.shape({
            code: PropTypes.shape({
              code: PropTypes.string,
            }),
          }),
        }),
      })
    ),
  }),
}

Onboarding.defaultProps = {
  allMemorableDays: [],
  background: null,
  confirmation: null,
  forwarding: null,
  intro: null,
  locale: null,
  location: {},
  questions: [],
  village: null,
}

export default Onboarding
