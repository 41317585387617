// @ts-nocheck
// eslint-disable-next-line import/prefer-default-export
export const getEligibleMembershipPopup = ({ edges = [], currentId }) => {
  const nodes = edges
    .filter((edge) => {
      const {
        node: { pageTemplates = [] },
      } = edge

      return (
        (pageTemplates && pageTemplates.find(({ id }) => id === currentId)) ||
        null
      )
    })
    .sort((edgeA, edgeB) => {
      return new Date(edgeA.node.updatedAt) - new Date(edgeB.node.updatedAt)
    })

  return nodes.length > 0 ? nodes[0].node : null
}
