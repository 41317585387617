import React from 'react'
import PropTypes from 'prop-types'
import theme from 'styles/theme'
import IconWrapper from './IconWrapper'

const CloseIcon = ({ color, width, display }) => (
  <IconWrapper width={width} display={display}>
    <svg width="100%" height="100%" viewBox="0 0 24 24">
      <path
        d="m12.7 12l5.6 5.6-0.7 0.7-5.6-5.6-5.6 5.6-0.7-0.7 5.6-5.6-5.7-5.6 0.7-0.7 5.6 5.6 5.6-5.6 0.7 0.7-5.5 5.6z"
        fill={color}
      />
    </svg>
  </IconWrapper>
)

CloseIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  display: PropTypes.string,
}

CloseIcon.defaultProps = {
  color: theme.colors.coaldark,
  width: [],
  display: 'inline-block',
}

export default CloseIcon
