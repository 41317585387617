import React from 'react'
import PropTypes from 'prop-types'
import theme from 'styles/theme'
import IconWrapper from './IconWrapper'

const PlusIcon = ({ color, width, display }) => (
  <IconWrapper width={width} display={display}>
    <svg width="100%" height="100%" viewBox="0 0 16 16">
      <path d="M7,7V3.5h1V7h3.5v1H8v3.5H7V8H3.5V7H7z" fill={color} />
    </svg>
  </IconWrapper>
)

PlusIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  display: PropTypes.string,
}

PlusIcon.defaultProps = {
  color: theme.colors.coaldark,
  width: [],
  display: 'inline-block',
}

export default PlusIcon
