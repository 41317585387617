import React from 'react'
import PropTypes from 'prop-types'
import theme from 'styles/theme'
import IconWrapper from './IconWrapper'

const YoutubeIcon = ({ color, width, height, display }) => (
  <IconWrapper width={width} height={height} display={display}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <title>TikTok_32x32</title>
      <defs>
        <filter colorInterpolationFilters="auto" id="filter-1">
          <feColorMatrix
            in="SourceGraphic"
            type="matrix"
            values="0 0 0 0 0.521569 0 0 0 0 0.576471 0 0 0 0 0.584314 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g
        id="TikTok_32x32"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g
          id="Group"
          transform="translate(1.000000, 1.000000)"
          stroke="#F0F0F0"
          strokeLinecap="square"
          strokeWidth="0.8">
          <circle id="Oval" cx="15" cy="15" r="15.4" />
        </g>
        <g filter="url(#filter-1)" id="tiktok-1324440243881667301_0">
          <g transform="translate(10.000000, 9.000000)">
            <path
              d="M6.30240468,0.0116665205 C7.05632891,0 7.80449799,0.00583326024 8.55266707,0 C8.59870825,0.892488816 8.91524132,1.80247741 9.55981776,2.43246952 C10.2043942,3.0799614 11.1137074,3.37745768 12,3.4766231 L12,5.82742697 C11.1712589,5.79826067 10.3367626,5.62326287 9.58283835,5.26160073 C9.25479498,5.10993597 8.9497722,4.91743838 8.65050457,4.71910753 C8.64474942,6.42241952 8.65625971,8.12573151 8.63899427,9.82321024 C8.5929531,10.6398667 8.32821635,11.4506898 7.86204946,12.1215148 C7.10812523,13.2415007 5.80170691,13.9706583 4.4607577,13.9939913 C3.63777171,14.0406574 2.81478572,13.8131602 2.11265781,13.3931655 C0.950118162,12.6990075 0.132887317,11.4273568 0.0120292348,10.0623739 C0.000518941262,9.77071089 -0.00523620553,9.47904788 0.00627408805,9.19321813 C0.10986673,8.08489869 0.650850529,7.02324532 1.49110196,6.29992105 C2.44645633,5.45993158 3.78165038,5.05743662 5.03051724,5.29660029 C5.04202753,6.15992281 5.00749665,7.02324532 5.00749665,7.88656784 C4.43773712,7.69990351 3.77014009,7.75240285 3.26944232,8.10239847 C2.90686807,8.34156214 2.63062102,8.70905753 2.48674235,9.12321901 C2.36588427,9.42071528 2.40041515,9.74737785 2.4061703,10.0623739 C2.54429382,11.0190286 3.45360702,11.8240185 4.42047168,11.7365196 C5.06504812,11.7306863 5.68084882,11.3515244 6.01464734,10.7973647 C6.12399513,10.6048671 6.24485321,10.4065363 6.25060836,10.1790391 C6.30815982,9.13488553 6.28513924,8.09656521 6.29089438,7.05241162 C6.29664953,4.70160775 6.28513924,2.35663714 6.30240468,0.0116665205 Z"
              id="Path"
              fill={color}
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </svg>
  </IconWrapper>
)

YoutubeIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  display: PropTypes.string,
}

YoutubeIcon.defaultProps = {
  color: theme.colors.coaldark,
  width: '40px',
  height: '40px',
  display: 'inline-block',
}

export default YoutubeIcon
