import React from 'react'
import PropTypes from 'prop-types'

import { Wrapper } from './MarkdownRenderer.style'

const MarkdownRenderer = ({ html, ...rest }) => (
  <Wrapper
    dangerouslySetInnerHTML={{
      __html: html.childMarkdownRemark ? html.childMarkdownRemark.html : html,
    }}
    {...rest}
  />
)

MarkdownRenderer.propTypes = {
  html: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.node,
    PropTypes.string,
  ]),
}

MarkdownRenderer.defaultProps = {
  html: '',
}

export default MarkdownRenderer
