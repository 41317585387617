import styled from 'styled-components'
import { Body12 } from 'components/Typography'
import theme from 'styles/theme'

export const Wrapper = styled.div`
  padding: ${theme.space[2]} ${theme.space[4]};
  display: flex;
  flex-direction: row;
  align-items: center;

  ${theme.mediaQueries.medium} {
    padding: ${theme.space[2]} 0;
  }
`

export const Copy = styled(Body12)`
  color: ${theme.colors.coallight};
  margin: 0;
  display: inline;
`

export const Divider = styled(Body12)`
  color: ${theme.colors.coallight};
  margin: 0 5px;
  display: inline;
`

export const Icon = styled.img`
  margin-right: 5px;
`
