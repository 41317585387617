import styled from 'styled-components'
import theme from 'styles/theme'
import Link from 'components/Link'
import { InlineButton } from '../Onboarding/Onboarding.style'

export const Wrapper = styled.div`
  align-items: center;
  color: ${theme.colors.white};
  display: flex;
  height: 90px;
  justify-content: space-between;
  padding: 20px 30px 20px 20px;
  position: relative;
  z-index: 1;

  ${theme.mediaQueries.medium} {
    height: 120px;
    padding: 30px;
  }

  ${({ position }) =>
    position === 'absolute' &&
    `
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
  `}
`

export const Logo = styled(Link)`
  display: block;
  flex: none;

  ${({ hide }) =>
    hide === 'true' &&
    `
    ${theme.mediaQueries.smallOnly} {
      display: none;
    }
  `}

  ${({ logoSize }) => {
    const [width = 60, height = 60] = logoSize || []
    return `
      svg {
        display: block;
        fill: ${theme.colors.white};
        height: ${height / 1.5}px;
        width: ${width / 1.5}px;

        ${theme.mediaQueries.medium} {
          height: ${height}px;
          width: ${width}px;
        }
      }
    `
  }}
`

export const Pager = styled.div`
  background: linear-gradient(
      ${theme.colors.sagelight},
      ${theme.colors.sagelight}
    )
    50% 50% / 15px 1px no-repeat ${theme.colors.white};
  border-radius: 100%;
  color: ${theme.colors.sagedark};
  display: flex;
  flex: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  height: 40px;
  transform: rotate(-45deg);
  width: 40px;

  ${({ position }) =>
    position === 'header'
      ? `
    ${theme.mediaQueries.medium} {
      display: none;
    }
  `
      : position === 'body' &&
        `
    margin-top: -40px;
    position: relative;
    top: -58px;

    ${theme.mediaQueries.smallOnly} {
      display: none;
    }
  `}

  &::before,
  &::after {
    padding: 3px 0;
    transform: rotate(45deg);
  }

  &::before {
    content: attr(data-step);
  }

  &::after {
    content: attr(data-total);
  }
`

export const Close = styled(InlineButton)`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: none;
  height: 24px;
  justify-content: center;
  margin-left: auto;
  width: 24px;

  ${theme.mediaQueries.medium} {
    align-self: flex-start;
    height: 36px;
    width: 36px;
  }

  [dir='rtl'] & {
    margin-left: 0;
    margin-right: auto;
  }

  &::before {
    background: linear-gradient(currentColor, currentColor) 50% 50% / 1px 100%
        no-repeat,
      linear-gradient(currentColor, currentColor) 50% 50% / 100% 1px no-repeat;
    content: '';
    display: block;
    height: 19px;
    position: relative;
    transform: rotate(45deg);
    width: 19px;

    ${theme.mediaQueries.medium} {
      height: 29px;
      width: 29px;
    }
  }
`
