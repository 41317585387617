import React from 'react'
import PropTypes from 'prop-types'
import theme from 'styles/theme'
import IconWrapper from './IconWrapper'

const TwitterIcon = ({ color, width, height, display }) => (
  <IconWrapper width={width} height={height} display={display}>
    <svg width={width} height={height} viewBox="0 0 40 40">
      <g transform="translate(2 2)" fill="none" fillRule="evenodd">
        <circle
          stroke={theme.colors.sagelight}
          strokeLinecap="square"
          cx="18"
          cy="18"
          r="18"
        />
        <path
          d="M26 13.539a6.57 6.57 0 0 1-1.886.517 3.291 3.291 0 0 0 1.444-1.816 6.584 6.584 0 0 1-2.085.796 3.282 3.282 0 0 0-5.593 2.993 9.32 9.32 0 0 1-6.766-3.429 3.28 3.28 0 0 0 1.016 4.382 3.266 3.266 0 0 1-1.487-.41v.041a3.284 3.284 0 0 0 2.633 3.218 3.29 3.29 0 0 1-1.482.056 3.286 3.286 0 0 0 3.066 2.28 6.587 6.587 0 0 1-4.077 1.404c-.265 0-.526-.015-.783-.045A9.295 9.295 0 0 0 15.032 25c6.038 0 9.34-5.001 9.34-9.338a9.22 9.22 0 0 0-.01-.425A6.657 6.657 0 0 0 26 13.54"
          fill={color}
        />
      </g>
    </svg>
  </IconWrapper>
)

TwitterIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  display: PropTypes.string,
}

TwitterIcon.defaultProps = {
  color: theme.colors.coaldark,
  width: '40px',
  height: '40px',
  display: 'inline-block',
}

export default TwitterIcon
