import React from 'react'
import PropTypes from 'prop-types'
import theme from 'styles/theme'
import IconWrapper from './IconWrapper'

export const CalendarIcon = ({ color, width, height, display, ...rest }) => {
  return (
    <IconWrapper {...rest} width={width} height={height} display={display}>
      <svg width="100%" height="100%" viewBox="0 0 23 23" fill="transparent">
        <path
          d="M17.1002 4.3999H4.5002C3.50608 4.3999 2.7002 5.20579 2.7002 6.1999V18.7999C2.7002 19.794 3.50608 20.5999 4.5002 20.5999H17.1002C18.0943 20.5999 18.9002 19.794 18.9002 18.7999V6.1999C18.9002 5.20579 18.0943 4.3999 17.1002 4.3999Z"
          stroke={color}
          strokeWidth="1.44"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.3999 2.59985V6.19985"
          stroke={color}
          strokeWidth="1.44"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.2002 2.59985V6.19985"
          stroke={color}
          strokeWidth="1.44"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.7002 9.7998H18.9002"
          stroke={color}
          strokeWidth="1.44"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconWrapper>
  )
}

CalendarIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  display: PropTypes.string,
}

CalendarIcon.defaultProps = {
  color: theme.colors.coaldark,
  width: [],
  height: [],
  display: 'inline-block',
}

export default CalendarIcon
