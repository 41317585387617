import { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Cookies from 'universal-cookie'
import { PopupContext } from 'components/Popup'
import { constructUrlPath } from 'utils/urlUtils'
import { globalHistory as history, useLocation } from '@reach/router'

const HeaderPopup = ({
  notification,
  urlProps,
  villageSlug,
  membershipPopUp,
  navOpen,
}) => {
  const {
    setPopupContent,
    type,
    showing,
    clearPopupContextState,
    isConsentBannerShowing,
  } = useContext(PopupContext)
  const { cookieHash = 0 } = notification || {}
  const cookies = new Cookies()
  const cookieName = `notice-${villageSlug}-${cookieHash}`
  const membershipCookieName =
    membershipPopUp && membershipPopUp.village
      ? `rp_[${membershipPopUp.village.code}]`
      : 'tbvsc'
  const suppress = () => !!cookies.get(cookieName)
  const ismembershipCookie = () => !!cookies.get(membershipCookieName)

  const {
    location: { href },
  } = history

  const isPresentInURL = () => href && !!href.match(/source=email|medium=email/)
  const [isConsentBanner, updateConsentBanner] = useState(false)
  const [isMembersCookie, updateMemberShipCookie] = useState(false)
  const [isNoticeCookie, updateIsNoticeCookie] = useState(false)
  const routerLocation = useLocation()

  // on each new page we clear state inside the popup provider
  // this allows us to ignore any timers set by the setPopUpContext function
  // our effect that follows will handle showing any popups if the cookies are missing
  useEffect(() => {
    clearPopupContextState()
  }, [routerLocation])

  // clear the pop ups when the nav menu is open
  useEffect(() => {
    if (navOpen) {
      clearPopupContextState()
    }
  }, [navOpen])

  // we use this timer initiated on page load to monitor the state of the cookies
  // and compare them to our state, if the popup cookies change we'll indirectly call the
  // effect hook containing the set logic
  useEffect(() => {
    const timer = setInterval(() => {
      // check for the acceptance of the membership cookie and update state when it changes
      const membersCookie = ismembershipCookie()
      if (!isMembersCookie && membersCookie) {
        updateMemberShipCookie(true)
      } else {
        updateMemberShipCookie(false)
      }

      // check for the acceptance of the notifcation cookie and update state when it changes
      const noticeCookie = suppress()
      if (!isNoticeCookie && noticeCookie) {
        updateIsNoticeCookie(true)
      } else {
        updateIsNoticeCookie(false)
      }

      // check if the coookie banner is showing
      const cookieConsent = isConsentBannerShowing()

      if (!isConsentBanner && cookieConsent) {
        updateConsentBanner(true)
      } else {
        updateConsentBanner(false)
      }
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [navOpen])

  const setCookie = (isMembershipPopup = false, shortCookie = false) => {
    // sometimes, if we're inside a moodal or navigate to a page where a timer has
    // already been activated by the content should not be set we set the cookie silently to expire
    // in a short period of time
    const shortCookieAge = 5

    if (isMembershipPopup) {
      let membershipCookieAge = shortCookieAge
      if (!shortCookie && membershipPopUp && membershipPopUp.cookieTimeout) {
        membershipCookieAge = membershipPopUp.cookieTimeout * 60
      }

      cookies.set(membershipCookieName, 1, {
        path: '/',
        maxAge: membershipCookieAge,
      })
    } else {
      cookies.set(cookieName, 1, {
        path: '/',
        maxAge: shortCookie ? shortCookieAge : notification.cookieTimeout * 60,
      })
    }
  }

  useEffect(() => {
    // stop the popup from opening up again if a popup is already open
    if (showing || navOpen) return () => {}

    const {
      // @ts-ignore
      bodyCopy: { childMarkdownRemark: { html: body } = {} } = {},
      ctaLabel,
      ctaUrl,
      ...rest
    } = notification || {}

    const {
      // @ts-ignore
      bodyCopy: { childMarkdownRemark: { html: bodyMembership } = {} } = {},
      secondaryCopy: {
        // @ts-ignore
        childMarkdownRemark: { html: secondaryCopyMembership } = {},
      } = {},
      legalCopy: {
        // @ts-ignore
        childMarkdownRemark: { html: legalCopyMembership } = {},
      } = {},
      // @ts-ignore
      primaryCta: { url, label: labelCtaMembership } = {},
      condensedVersion,
    } = membershipPopUp || {}

    let popup = null
    // if notif data and there is no cookie, set the popup
    // and there's not currently a membership popup open

    if (notification && !suppress()) {
      popup = Object.assign({}, rest, {
        beforeClose: (short) => setCookie(false, short),
        waiting: true,
        body,
        type: 'notification',
        cta: {
          label: ctaLabel,
          url: constructUrlPath({ url: ctaUrl, villageSlug, ...urlProps }),
        },
      })
      // @ts-ignore)
      return setPopupContent(popup)
    }

    // prevent membership popup from showing if cooke banner is showing
    if (isConsentBannerShowing()) return () => {}
    // think if there's a given url condition, the members popup shouldn't fire
    if (isPresentInURL()) return () => {}

    // if there is membership popup data and there's no cookie set
    // and there's not currnently a notif modal open
    if (membershipPopUp && !ismembershipCookie() && type !== 'notification') {
      popup = {
        ...membershipPopUp,
        beforeClose: (short) => setCookie(true, short),
        body: bodyMembership,
        cta: {
          label: labelCtaMembership,
          url: constructUrlPath({ url, villageSlug, ...urlProps }),
        },
        waiting: true,
        type: 'membershipPopUp',
        legalCopy: legalCopyMembership,
        secondaryCopy: secondaryCopyMembership,
        condensedVersion,
      }
      // @ts-ignore
      return setPopupContent(popup)
    }

    return () => {} // returned function serves as componentWillUnmount

    // will run when any of notification data, or membershpPopUp data is created/changed or the cookie state changes
  }, [
    notification,
    membershipPopUp,
    isMembersCookie,
    isNoticeCookie,
    isConsentBanner,
    navOpen,
  ])

  return null
}

HeaderPopup.propTypes = {
  notification: PropTypes.shape({
    bodyCopy: PropTypes.shape({
      childMarkdownRemark: PropTypes.shape({
        html: PropTypes.string,
      }),
    }),
    cookieHash: PropTypes.string,
    cookieTimeout: PropTypes.number,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
    dismiss: PropTypes.string,
    headline: PropTypes.string,
  }),
  urlProps: PropTypes.shape({
    nodeLocale: PropTypes.string,
    pageLevel: PropTypes.string,
    translatedServices: PropTypes.instanceOf(Object),
    translatedSlugs: PropTypes.instanceOf(Object),
  }),
  villageSlug: PropTypes.string,
  navOpen: PropTypes.bool,
  membershipPopUp: PropTypes.instanceOf(Object),
}

HeaderPopup.defaultProps = {
  notification: null,
  urlProps: {},
  villageSlug: '',
  navOpen: false,
  membershipPopUp: {},
}

export default HeaderPopup
